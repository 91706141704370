import { createContext, useState, useEffect,useContext } from "react";
import { onAuthStateChangedListener, createUserDocumentFromAuth,  } from "../util/firebase/util.firebase";
import { UserContext } from "./user.context";

//as the actual value you want to access
export const IdeasContext = createContext({
    ideas: null,
    setIdeas: () => null,
    filteredIdeas: null,
    setFilteredIdeas: () => null,

});


export const IdeasProvider = ({children}) => {
    const [ideas, setIdeas] = useState(null);
    const [filteredIdeas, setFilteredIdeas] = useState(null);
    const value = {ideas, setIdeas,filteredIdeas, setFilteredIdeas
                
    };

    

    return <IdeasContext.Provider value={value}>{children}</IdeasContext.Provider>
}