import { useState } from "react";
import { signInWithGooglePopup, createUserDocumentFromAuth, signInAuthUserWithEmailandPassword } from "../../../util/firebase/util.firebase";
import PartsMinisFormInput from "../minis/parts.minis.formInput/parts.minis.formInput";
import './parts.components.auth.signin.styles.scss';
import PartsMinisButton from "../minis/parts.minis.button/parts.minis.button";

const defaultSignInFormFields = {
    email: "",
    password: "",

}



function PartsAuthSignInForm() {
    const [formfields, setFormFields] = useState(defaultSignInFormFields);
    const { email, password } = formfields;

    const resetFormFields = () => {
        setFormFields(defaultSignInFormFields);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const {user} = await signInAuthUserWithEmailandPassword(email, password);
            resetFormFields();
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    const SignInWithGoogle = async () => {
        await signInWithGooglePopup();
        //console.log(user);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formfields, [name]: value });
    };

    return (
        <div className='sign-up-container'>
            <h2>Already have an Account?</h2>
            <span>Sign in with your email and password</span>
            <form onSubmit={handleSubmit}>

                <PartsMinisFormInput label="Email" type="email" required onChange={handleChange} name="email" value={email} autoComplete="email" />

                <PartsMinisFormInput label="Password" type="password" required onChange={handleChange} name="password" value={password} autoComplete="current-password" />
                <div className="signIn-button-container">
                    <PartsMinisButton type="sumbit">Sign In </PartsMinisButton>
                    <PartsMinisButton type="button" onClick={SignInWithGoogle} buttonType="google">Google Sign In</PartsMinisButton>
                </div>
            </form>
        </div>
    );
}

export default PartsAuthSignInForm