import Form from 'react-bootstrap/Form';

const CreateFormGroupArea = ({ id, value, title, helpText, isRequired, onChangeHandler, rows }) => {
    return (
        <Form.Group className="mb-3" style={{ color: "black" }}>
            <Form.Label htmlFor={id}>{title}</Form.Label>
            <Form.Control
                as="textarea"
                id={id}
                name={id}
                aria-describedby={`${id}HelpBlock`}
                defaultValue={value}
                required={isRequired}
                onChange={onChangeHandler}
                rows={rows}
            />
            <Form.Text id={`${id}HelpBlock`} >
                {helpText}
            </Form.Text>
        </Form.Group>
    )
}

export default CreateFormGroupArea;