import { Fragment } from "react";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

const SharedComponentIdeaCard = (props) => {
    const { idea, page } = props;
    let cardClass='';
    //console.log (character);
    (page==="home" ? cardClass="col-lg-3 col-md-4 col-sm-6 px-2 mb-4" : cardClass ="col-md-6 col-sm-6 px-2 mb-4")
    return (
        <Fragment>
            <div key={idea.id} className={cardClass} >
            <div className="card product-card">
                {/*<button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button>*/}
                <Link className="card-img-top d-block overflow-hidden" to={idea.url}>
                
                    
                    <img className="image-fit-width" src={idea.pictures.thumbnail} alt={idea.name} />
                </Link>
                <div className="card-body py-2">
                    <Stack direction="horizontal" gap={2}>
                {idea.badge.map((badge) => {
                        return(<Badge pill key={badge.id} bg={badge.classText}>{badge.badgeText}</Badge>)
                    })}
                    </Stack>
                    <a className="product-meta d-block fs-xs pb-1" href={idea.url}>{idea.subtitle}</a>
                    <h3 className="product-title fs-sm"><Link href={idea.url}>{idea.name}</Link></h3>
                    <div className="d-flex justify-content-between">
                        <label className="m-1 form-option-label rounded-circle"><i className={idea.genre.icon}></i></label> 
                        {/*<div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-half active"></i><i className="star-rating-icon ci-star"></i></div>*/}
                        
                    </div>

                </div>
                <div className="card-body card-body-hidden">
                    {/*<p>{idea.cardText}</p>*/}

                    {/*<div className="text-center"><a className="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal"><i className="ci-eye align-middle me-1"></i>Quick view</a></div>*/}
                </div>
            </div>
            <hr className="d-sm-none" />
        </div>
        </Fragment>
    )
}

export default SharedComponentIdeaCard;