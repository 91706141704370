import { Fragment } from 'react';
import SharedComponentCardCategory from '../../../shared/components/shared.components.card/shared.components.card.category.class';

const PartsHomeQuickCats = () => {

    const categories = [
        {
            id: 1,
            title: 'Characters',
            picUrl: 'https://images.liltiffyrps.com/cats/erinCat.jpg',
            linkUrl: '/models/all',
        },
        {
            id: 2,
            title: 'Story Ideas',
            picUrl: 'https://images.liltiffyrps.com/cats/tiffCat.jpg',
            linkUrl: '/search/ideas',
        },/*
        {
            id: 3,
            title: 'Events',
            picUrl: 'https://images.liltiffyrps.com/cats/elizaCat.jpg',
            linkUrl: '/events/all',
        },
        */

    ]

    return (
        <Fragment>
            <section className="container position-relative pt-3 pt-lg-0 pb-5 mt-lg-n3" style={{ zIndex: 10 }}>
                <div className="row">
                    <div className="col-xl-8 col-lg-9">
                        <div className="card border-0 shadow-lg">
                            <div className="card-body px-3 pt-grid-gutter pb-0">
                                <div className="row g-0 ps-1">
                                    {categories.map(({ id, title, picUrl, linkUrl }) => (
                                        <SharedComponentCardCategory
                                            key={id}
                                            title={title}
                                            picUrl={picUrl}
                                            linkUrl={linkUrl}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );

}

export default PartsHomeQuickCats;