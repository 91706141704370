import { Fragment } from "react";

const PartsModelEditCharLink = (props) => {
    const { mt, cid, status_state } = props;
    //console.log("Mt:", mt);
    //console.log("Cid:", cid);

    const NextOnClickHandler = () => {
        
            
            fetch("https://api.liltiffyrps.com/modelDomain/V2/setModelToNext", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&cid=${cid}`
            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    window.location.reload();
    
                })
    
    
    
        ;
    }

    const NextUnplannedOnClickHandler = () => {
        
            
        fetch("https://api.liltiffyrps.com/modelDomain/V2/setModelToNextUnplanned", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&cid=${cid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.status_code)
                window.location.reload();

            })



    ;
}


    return (
        <Fragment>
            {mt === 'a' || mt === 'e' ?
                <Fragment>
                    <a className = "btn btn-primary" href = {`/admin/edit/model/${cid}`} target="_blank">Edit Character</a>
                    {status_state==="pipeline" && mt === 'a'?
                    <span onClick={NextOnClickHandler} className = "btn btn-primary" >Move To Next</span>
                    :""}
                    {status_state==="unreleased" && mt === 'a'?
                    <span onClick={NextUnplannedOnClickHandler} className = "btn btn-primary" >Move To Next</span>
                    :""}
                    <br />
                </Fragment>
                : ""}
        </Fragment>
    )


}

export default PartsModelEditCharLink;