import { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const PartsNavBarTop = () => {
    return (
        <Fragment>
               <Navbar className="topbar topbar-dark bg-dark">
                <Container>
                
                </Container>
            </Navbar>
        
        </Fragment>
    )
}

export default PartsNavBarTop;