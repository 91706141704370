import { Fragment } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

const PartsHomeCarousel = () => {

    const carouselPieces = [
        {
            Use: true,
            BGColor: "#ebcdc1",
            BackPic: "https://images.liltiffyrps.com/sliders/slider8.png",
            BackPicText: "Version8",
            Title3: "Verson 8 has arrived",
            Title2: "Lil Tiffy's Rps",
            Text1: "Please be patient as it is updated.",
            ButtonUsed: false,
            ButtonText: "",
            ButtonLink: "",
        },
        {
            Use: true,
            BGColor: "#fbd8d8",
            BackPic: "https://images.liltiffyrps.com/sliders/slider7.png",
            BackPicText: "Characters",
            Title3: "",
            Title2: "Characters",
            Text1: "with photos and profiles",
            ButtonUsed: true,
            ButtonText: "View Characters",
            ButtonLink: "/models/all",
        },
        {
            Use: false,
        },
        {
            Use: false,
        },
        {
            Use: false,
        }

    ];
    const carouselPiece = carouselPieces[0];

    return (
        <Fragment>
            <Carousel>
                {(carouselPieces[0].Use ?
                    <Carousel.Item style={{ backgroundColor: carouselPieces[0].BGColor }} key={carouselPieces[0].BackPicText}>
                        <img style={{ marginLeft: "auto", marginRight: "0", display: "flex", justifyContent: "right" }} className="d-block order-lg-2 me-lg-n5 flex-shrink-0" src={carouselPieces[0].BackPic} alt={carouselPieces[0].BackPicText} />
                        <Carousel.Caption>
                            <Card>
                                <h3 className="h2 text-dark fw-light pb-1 from-start">{carouselPieces[0].Title3}</h3>
                                <h2 className="text-dark display-5 from-start delay-1">{carouselPieces[0].Title2}</h2>
                                <p className="fs-lg text-dark pb-3 from-start delay-2">{carouselPieces[0].Text1}</p>
                                {(carouselPieces[0].ButtonUsed ? <div className="d-table scale-up delay-4 mx-auto mx-lg-0"><Link className="btn btn-primary" to={carouselPieces[0].ButtonLink}>{carouselPieces[0].ButtonText}<i className="ci-arrow-right ms-2 me-n1"></i></Link></div> : "")}
                            </Card>
                        </Carousel.Caption>
                    </Carousel.Item>
                    : "")}

                {(carouselPieces[1].Use ?
                    <Carousel.Item style={{ backgroundColor: carouselPieces[1].BGColor }} key={carouselPieces[1].BackPicText}>
                        <img style={{ marginLeft: "auto", marginRight: "0", display: "flex", justifyContent: "right" }} className="d-block order-lg-2 me-lg-n5 flex-shrink-0" src={carouselPieces[1].BackPic} alt={carouselPieces[1].BackPicText} />
                        <Carousel.Caption>
                            <Card>
                                <h3 className="h2 text-dark fw-light pb-1 from-start">{carouselPieces[1].Title3}</h3>
                                <h2 className="text-dark display-5 from-start delay-1">{carouselPieces[1].Title2}</h2>
                                <p className="fs-lg text-dark pb-3 from-start delay-2">{carouselPieces[1].Text1}</p>
                                {(carouselPieces[1].ButtonUsed ? <div className="d-table scale-up delay-4 mx-auto mx-lg-0"><Link className="btn btn-primary" to={carouselPieces[1].ButtonLink}>{carouselPieces[1].ButtonText}<i className="ci-arrow-right ms-2 me-n1"></i></Link></div> : "")}
                            </Card>
                        </Carousel.Caption>
                    </Carousel.Item>
                    : "")}

                {(carouselPieces[2].Use ?
                    <Carousel.Item style={{ backgroundColor: carouselPieces[2].BGColor }} key={carouselPieces[2].BackPicText}>
                        <img style={{ marginLeft: "auto", marginRight: "0", display: "flex", justifyContent: "right" }} className="d-block order-lg-2 me-lg-n5 flex-shrink-0" src={carouselPieces[2].BackPic} alt={carouselPieces[2].BackPicText} />
                        <Carousel.Caption>
                            <Card>
                                <h3 className="h2 text-dark fw-light pb-1 from-start">{carouselPieces[2].Title3}</h3>
                                <h2 className="text-dark display-5 from-start delay-1">{carouselPieces[2].Title2}</h2>
                                <p className="fs-lg text-dark pb-3 from-start delay-2">{carouselPieces[2].Text1}</p>
                                {(carouselPieces[2].ButtonUsed ? <div className="d-table scale-up delay-4 mx-auto mx-lg-0"><Link className="btn btn-primary" to={carouselPieces[2].ButtonLink}>{carouselPieces[2].ButtonText}<i className="ci-arrow-right ms-2 me-n1"></i></Link></div> : "")}
                            </Card>
                        </Carousel.Caption>
                    </Carousel.Item>
                    : "")}

                {(carouselPieces[3].Use ?
                    <Carousel.Item style={{ backgroundColor: carouselPieces[3].BGColor }} key={carouselPieces[3].BackPicText}>
                        <img style={{ marginLeft: "auto", marginRight: "0", display: "flex", justifyContent: "right" }} className="d-block order-lg-2 me-lg-n5 flex-shrink-0" src={carouselPieces[3].BackPic} alt={carouselPieces[3].BackPicText} />
                        <Carousel.Caption>
                            <Card>
                                <h3 className="h2 text-dark fw-light pb-1 from-start">{carouselPieces[3].Title3}</h3>
                                <h2 className="text-dark display-5 from-start delay-1">{carouselPieces[3].Title2}</h2>
                                <p className="fs-lg text-dark pb-3 from-start delay-2">{carouselPieces[3].Text1}</p>
                                {(carouselPieces[3].ButtonUsed ? <div className="d-table scale-up delay-4 mx-auto mx-lg-0"><Link className="btn btn-primary" to={carouselPieces[3].ButtonLink}>{carouselPieces[3].ButtonText}<i className="ci-arrow-right ms-2 me-n1"></i></Link></div> : "")}
                            </Card>
                        </Carousel.Caption>
                    </Carousel.Item>
                    : "")}

                {(carouselPieces[4].Use ?
                    <Carousel.Item style={{ backgroundColor: carouselPieces[4].BGColor }} key={carouselPieces[4].BackPicText}>
                        <img style={{ marginLeft: "auto", marginRight: "0", display: "flex", justifyContent: "right" }} className="d-block order-lg-2 me-lg-n5 flex-shrink-0" src={carouselPieces[4].BackPic} alt={carouselPieces[4].BackPicText} />
                        <Carousel.Caption>
                            <Card>
                                <h3 className="h2 text-dark fw-light pb-1 from-start">{carouselPieces[4].Title3}</h3>
                                <h2 className="text-dark display-5 from-start delay-1">{carouselPieces[4].Title2}</h2>
                                <p className="fs-lg text-dark pb-3 from-start delay-2">{carouselPieces[4].Text1}</p>
                                {(carouselPieces[4].ButtonUsed ? <div className="d-table scale-up delay-4 mx-auto mx-lg-0"><Link className="btn btn-primary" to={carouselPieces[4].ButtonLink}>{carouselPieces[4].ButtonText}<i className="ci-arrow-right ms-2 me-n1"></i></Link></div> : "")}
                            </Card>
                        </Carousel.Caption>
                    </Carousel.Item>
                    : "")}

            </Carousel>
        </Fragment>
    )

}

export default PartsHomeCarousel;
