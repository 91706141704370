import PartsAuthSignUpForm from "../../parts/components/parts.components.auth.signup/parts.components.auth.signup";
import PartsAuthSignInForm from "../../parts/components/parts.components.auth.signin/parts.components.auth.signin";
import './routes.auth.styles.scss';

const Authentication = () => {
    
    



    return (
        <div className='authentication-container'>
            <PartsAuthSignInForm />
            <PartsAuthSignUpForm />
        </div>
    )
}

export default Authentication;