import { Fragment, useContext } from "react";
import PartsMinisTitle from "../../parts/components/minis/parts.minis.title/parts.minis.title";
import PartsAdminSidebar from "../../parts/components/parts.components.admin.sidebar/parts.components.admin.sidebar";
import { Outlet } from "react-router";
import { UserContext } from "../../contexts/user.context";
const RouteAdminClass = () => {
    const { mt } = useContext(UserContext);
    return <Fragment>
        {mt==="a"||mt==="e"?
        <Fragment>
        <PartsMinisTitle title="Admin" midText="" midLink="" hasMid={false} ></PartsMinisTitle>
        <div className="container pb-5 mb-2 mb-md-4">
            <div className="row">
            <PartsAdminSidebar />
            <section className="col-lg-8">
                <Outlet />

            </section>
            </div>
        </div>
        </Fragment>
        :""}
    </Fragment>

}

export default RouteAdminClass;