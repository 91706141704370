import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import PartsModelTitle from "../../parts/components/parts.components.model.title/parts.components.model.title";
import PartsModelGallery from "../../parts/components/parts.components.model.gallery/parts.components.model.gallery";
import Accordion from 'react-bootstrap/Accordion';
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import PartsModelGenreSelect from "../../parts/components/parts.components.model.genreselect/parts.compoenents.model.genreselect";
import PartsModelEditCharLink from "../../parts/components/parts.components.model.editcharLink/parts.components.model.editcharLink";
import PartsMinisButton from "../../parts/components/minis/parts.minis.button/parts.minis.button";
import PartsMinisFormInput from "../../parts/components/minis/parts.minis.formInput/parts.minis.formInput";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CreateFormGroupText from "../../parts/components/minis/parts.minis.formPieces/parts.minis.textbox";
import CreateFormGroupArea from "../../parts/components/minis/parts.minis.formPieces/parts.minis.textarea";
import CreateFormGroupNumber from "../../parts/components/minis/parts.minis.formPieces/parts.minis.numberbox";
import CreateFormGroupReadOnly from "../../parts/components/minis/parts.minis.formPieces/parts.minis.readonly";

const defaultRoleplayFormFields = {

    rps1UserKey: 2,
    rps1StoryKey: 72,
    rps1Subtitle: "",
    rps1CardText: "",
    rps1Fage: 18,
    rps1Mage: 18,
    rps1MaleName: "",
    rps1SettingsKey: 1,

}

const RouteNewRoleplayClass = () => {
    let { ideaId } = useParams();

    const [rp, setRp] = useState([]);
    const { mt } = useContext(UserContext);

    /*
    const [unplannedFormfields, setUnplannedFormFields] = useState(defaultUnplannedFormFields);*/
    const [rpFormFields, setRpFormFields] = useState(defaultRoleplayFormFields);
    const { rps1UserKey, rps1StoryKey, rps1Subtitle, rps1CardText, rps1Fage, rps1Mage, rps1MaleName, rps1SettingsKey, } = rpFormFields;


    /*
    const { unFirst, unNick, unLast, unGrade, unPubertyS, unPubertyE } = unplannedFormfields;

    const { cha1First, cha1Nick, cha1Last, cha1Grade, cha1PubertyS, cha1PubertyE, cha1BirthMonth, cha1BirthDay, cha1Real, cha1Anime,
        cha2Ageless, cha2CardText, cha2Location, cha2Occupation, cha2Subtitle, cha2Race, cha2AltName, cha2Thumb, } = characterFormfields;*/

    //console.log(topCharacters);

    const { currentUser } = useContext(UserContext);
    const [siteUsers, setSiteUsers] = useState([]);
    const [ideas, setIdeas] = useState([]);
    const [settings, setSettings] = useState([]);

    /*
    const [pickedGenre, setPickedGenre] = useState([]);
    const [pickedCat, setPickedCat] = useState([]);
    const [cats, setCats] = useState([]);
    const [characterCats, setCharacterCats] = useState([]);
    */

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/getUserInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setSiteUsers(users.body);
            })


    }, [currentUser]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/getIdeaInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setIdeas(users.body);
            })


    }, [currentUser]);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/getSettingInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setSettings(users.body);
            })


    }, [currentUser]);

    /*
    const handleUnplannedChange = (event) => {
        const { name, value } = event.target;
        //console.log ("Name: ",name," Value: ",value)
        setUnplannedFormFields({ ...unplannedFormfields, [name]: value });
        //console.log(unplannedFormfields);
    };
    */

    const handleRpChange = (event) => {
        const { name, value } = event.target;
        console.log("Name: ", name, "Value: ", value);
        setRpFormFields({ ...rpFormFields, [name]: value });
        //console.log(ideaFormFields);
    };

    /*
        const handleNewGenreChange = (event) => {
            const { value } = event.target;
            setPickedGenre(value);
            console.log(pickedGenre);
        };
    
        const handleNewCatChange = (event) => {
            const { value } = event.target;
            setPickedCat(value);
            console.log(pickedGenre);
        };
    
        const resetFormFields = () => {
            setUnplannedFormFields(defaultUnplannedFormFields);
            setCharacterFormFields(defaultCharacterFormFields);
        }
    
        const handleUnPlannedSubmit = async (event) => {
            event.preventDefault();
    
            try {
                let uid = -1;
                if (currentUser !== null) {
                    uid = currentUser.uid;
                }
    
                fetch("https://api.liltiffyrps.com/modelDomain/V2/setModelToPipeline", {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&first=${unFirst}&nick=${unNick}&last=${unLast}&age=${unGrade}&pubertyS=${unPubertyS}&pubertyE=${unPubertyE}`
    
                }
                )
                    .then(response => response.json())
                    .then(users => {
                        //console.log (users.status_code)
                        switch (users.status_code) {
                            case 200:
                                window.location.reload();
                                break;
                            case 300:
                                //not found
    
                                break;
    
                            case 400:
                            //unauthorized
    
                        }
    
                    })
                //const {user} = await signInAuthUserWithEmailandPassword(email, password);
                resetFormFields();
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        alert('Incorrect Password for Email');
                        break;
                    case 'auth/user-not-found':
                        alert('No user associated with this email');
                        break;
                    default:
                        console.log(error);
                }
    
            }
        };
    
        const handleNewGenreSubmit = async (event) => {
            event.preventDefault();
            
            try {
                let uid = -1;
                if (currentUser !== null) {
                    uid = currentUser.uid;
                }
    
                fetch("https://api.liltiffyrps.com/modelDomain/V2/setNewGenreModel", {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&gid=${pickedGenre}`
    
                }
                )
                    .then(response => response.json())
                    .then(users => {
                        //console.log (users.status_code)
                        switch (users.status_code) {
                            case 200:
                                window.location.href = `/admin/edit/model/${users.body.cid}`;
                                //window.location.reload();
                                break;
                            case 300:
                                //not found
    
                                break;
    
                            case 400:
                            //unauthorized
    
                        }
    
                    })
                //const {user} = await signInAuthUserWithEmailandPassword(email, password);
                resetFormFields();
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        alert('Incorrect Password for Email');
                        break;
                    case 'auth/user-not-found':
                        alert('No user associated with this email');
                        break;
                    default:
                        console.log(error);
                }
    
            }
        };
    
        const handleNewCatSubmit = async (event) => {
            event.preventDefault();
            
            try {
                let uid = -1;
                if (currentUser !== null) {
                    uid = currentUser.uid;
                }
    
                fetch("https://api.liltiffyrps.com/modelDomain/V2/setNewCatForCharacter", {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&catid=${pickedCat}`
    
                }
                )
                    .then(response => response.json())
                    .then(users => {
                        //console.log (users.status_code)
                        switch (users.status_code) {
                            case 200:
                                window.location.reload();
                                break;
                            case 300:
                                //not found
    
                                break;
    
                            case 400:
                            //unauthorized
    
                        }
    
                    })
                //const {user} = await signInAuthUserWithEmailandPassword(email, password);
                resetFormFields();
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        alert('Incorrect Password for Email');
                        break;
                    case 'auth/user-not-found':
                        alert('No user associated with this email');
                        break;
                    default:
                        console.log(error);
                }
    
            }
        };
    
        */



    const handleEditRPSubmit = async (event) => {
        event.preventDefault();
        //console.log(ideaFormFields);

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }
            //const serviceBody = `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`
            //console.log(serviceBody);
            fetch("https://api.liltiffyrps.com/roleplayDomain/V2/setNewRP", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&rps1UserKey=${rps1UserKey}&rps1StoryKey=${rps1StoryKey}&rps1Subtitle=${rps1Subtitle}&rps1CardText=${rps1CardText}&rps1Fage=${rps1Fage}&rps1Mage=${rps1Mage}&rps1MaleName=${rps1MaleName}&rps1SettingsKey=${rps1SettingsKey}`
            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.href = `/admin/edit/idea/${users.body.id}`;
                            //window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            //resetFormFields();

        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };


    //console.log(idea);
    //console.log(ideaFormFields);
    //console.log(character);
    //console.log(characterFormfields);
    //console.log(cats);
    //console.log(characterCats);


    return (
        <Fragment>
            {mt === "a" ?
                <div className="container" >
                    {/* Gallery + details*/}
                    <div className="bg-primary shadow-lg rounded-3 px-4 py-3 mb-5">
                        <div className="px-lg-3">
                            <h2 className="text-light text-center">New Roleplay</h2>
                            <p className="bg-danger text-light text-center"></p>
                            <p className="bg-warning text-dark text-center"></p>

                        </div>
                    </div>


                    <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                        <div className="px-lg-3">
                            < div className="row">
                                <div>
                                    <Container>
                                        <h3 className="text-center">RPS1: Roleplay Information</h3>
                                        <p className="bg-danger text-light text-center"></p>
                                        <Form onSubmit={handleEditRPSubmit}>
                                            <Form.Group className="mb-3" style={{ color: "black" }}>
                                                <Form.Select onChange={handleRpChange} aria-label="Default select example" name="rps1UserKey" value={rps1UserKey}>
                                                    {siteUsers?.map((genre) => {
                                                        return (
                                                            <Fragment key={genre.id}>
                                                                <option value={genre.id}>{genre.name}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3" style={{ color: "black" }}>
                                                <Form.Select onChange={handleRpChange} aria-label="Default select example" name="rps1StoryKey" value={rps1StoryKey}>
                                                    {ideas?.map((genre) => {
                                                        return (
                                                            <Fragment key={genre.id}>
                                                                <option value={genre.id}>{genre.name}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3" style={{ color: "black" }}>
                                                <Form.Select onChange={handleRpChange} aria-label="Default select example" name="rps1SettingsKey" value={rps1SettingsKey}>
                                                    {settings?.map((genre) => {
                                                        return (
                                                            <Fragment key={genre.id}>
                                                                <option value={genre.id}>{genre.name}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <CreateFormGroupText
                                                id="rps1MaleName"
                                                isRequired={false}
                                                onChangeHandler={handleRpChange}
                                                value={rps1MaleName}
                                                title="MC's Name"
                                                helpText="Please enter the main character (male)'s name." >
                                            </CreateFormGroupText>
                                            <CreateFormGroupNumber
                                                id="rps1Fage"
                                                isRequired={true}
                                                onChangeHandler={handleRpChange}
                                                value={parseInt(rps1Fage)}
                                                title="Female Age"
                                                helpText="Please the age of the X+0 female characters." >
                                            </CreateFormGroupNumber>
                                            <CreateFormGroupNumber
                                                id="rps1Mage"
                                                isRequired={true}
                                                onChangeHandler={handleRpChange}
                                                value={parseInt(rps1Mage)}
                                                title="Male Age"
                                                helpText="Please enter the male's age." >
                                            </CreateFormGroupNumber>
                                            <CreateFormGroupText
                                                id="rps1Subtitle"
                                                isRequired={false}
                                                onChangeHandler={handleRpChange}
                                                value={rps1Subtitle}
                                                title="Short Description Override"
                                                helpText="Please enter the short description of the roleplay." >
                                            </CreateFormGroupText>
                                            <CreateFormGroupArea
                                                id="rps1CardText"
                                                isRequired={false}
                                                onChangeHandler={handleRpChange}
                                                value={rps1CardText}
                                                title="Long Description Override"
                                                helpText="Please enter the long description of the roleplay."
                                                rows="4" >
                                            </CreateFormGroupArea>
                                            {/*
                                            <Form.Group className="mb-3" style={{ color: "black" }}>
                                                <Form.Select onChange={handleIdeaChange} aria-label="Default select example" name="str2Genre" value={str2Genre}>
                                                    {genres?.map((genre) => {
                                                        return (
                                                            <Fragment key={genre.id}>
                                                                <option value={genre.id}>{genre.name}</option>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Form.Select>
                                                </Form.Group>*/}
                                            <Button variant="primary" type="submit">
                                                Add Roleplay
                                            </Button>
                                        </Form>

                                    </Container>
                                </div>
                            </div>

                        </div>
                    </div>{/*
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">CHA2: Genre Specific Character Information</h3>
                                                    <p className="bg-success text-light text-center">All fields in this section apply to the specific version of the character tied to the genre.</p>
                                                    <Form onSubmit={handleEditCharacterSubmit}>
                                                        <Form.Group className="mb-3" style={{ color: "black" }}>
                                                            <Form.Label column sm="2">
                                                                CiD
                                                            </Form.Label>
                                                            <Form.Control type="number" placeholder={thisCharacter.keys.cid} readOnly />
                                                            <Form.Text id={`CiDHelpBlock`} >
                                                                This is the charcter's genre form key and the primary key to CH2.  In version 8 it is the main key used to access a specific genre character.  It cannot be changed.
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <CreateFormGroupText
                                                            id="cha2AltName"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2AltName}
                                                            title="Genre Specific Name Override"
                                                            helpText="Enter the name of the character here only if it differs from the base character's name.  If the base character's name is used for this genre please leave this field blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Race"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Race}
                                                            title="Race Override"
                                                            helpText="Enter the race of the character here only if it differs from the model's race.  If the model's race is used for this genre please leave this field blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Subtitle"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Subtitle}
                                                            title="Short Description"
                                                            helpText="Please enter the short description of the character.  This should be a phrase and appears on the character card" >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupArea
                                                            id="cha2CardText"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2CardText}
                                                            title="Long Description"
                                                            helpText="Please enter the long description of the character."
                                                            rows="4" >
                                                        </CreateFormGroupArea>
                                                        <CreateFormGroupNumber
                                                            id="cha2Ageless"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha2Ageless)}
                                                            title="Is Ageless"
                                                            helpText="Please enter 0 if the charcter ages normally and 1 if the character does not age, such as robots and goddesses." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupText
                                                            id="cha2Location"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Location}
                                                            title="Location"
                                                            helpText="Enter the place in which the character lives" >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Occupation"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Occupation}
                                                            title="Occupation"
                                                            helpText="Enter the occupation or fantasy class of the character." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Thumb"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Thumb}
                                                            title="Thumbnail Override"
                                                            helpText="Typically the card thumbnail of the character will be the first picture in the gallery.  Enter a thumbnail picture file name to override that with the one provided for this genre character specifically." >
                                                        </CreateFormGroupText>
                                                        <Button variant="primary" type="submit">
                                                            Update Character
                                                        </Button>
                                                    </Form>
                                                </Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>

                        } */}

                </div > : ""}







        </Fragment >
    )

}

export default RouteNewRoleplayClass;