import { Fragment } from "react";
import PartsHomeTopCharacters from "../../parts/components/parts.components.home.topCharacters/parts.components.home.topCharacters";
import PartsHomeQuickCats from "../../parts/components/parts.components.home.quickCats/parts.components.home.quickCats";
import PartsHomeCarousel from "../../parts/components/parts.components.home.carousel/parts.components.home.carousel";
import PartsHomeBirthDayCharacters from "../../parts/components/parts.components.home.BirthDayCharacters/parts.components.home.birthDayCharacters";

const RouteHomeClass = () => {

    return (
        <Fragment>
            <PartsHomeCarousel />
            <PartsHomeQuickCats />
            <PartsHomeBirthDayCharacters />
            <PartsHomeTopCharacters />
        </Fragment>
    )

}


export default RouteHomeClass;