import { Fragment } from "react"
import { Outlet } from "react-router-dom";
import SharedComponentFooter from "../../shared/components/shared.components.footer/shared.components.footer.class";
import PartsNavBarTop from "../../parts/components/parts.components.navbar.topbar/parts.components.navbar.topbar";
import PartsNavBarSearch from "../../parts/components/parts.components.navbar.searchbar/parts.components.navbar.searchbar";
import PartsNavBarNav from "../../parts/components/parts.components.navbar.navbar/parts.components.navbar.navbar";

const RouteNavBarClass = () => {
    return (
        <Fragment>
            <main className="page-wrapper">
            <header className="bg-light shadow-sm navbar-sticky">
                <PartsNavBarTop />
                <div className="navbar-sticky bg-light">
                    <PartsNavBarSearch />
                    <PartsNavBarNav />
                </div>
            </header>
            <Outlet />
            </main>
            <SharedComponentFooter />
        
        </Fragment>
    )
}

export default RouteNavBarClass;