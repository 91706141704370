import { Fragment } from "react";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

const SharedComponentCard = (props) => {
    const { character, page } = props;
    let cardClass='';
    //console.log (character);
    (page==="home" ? cardClass="col-lg-3 col-md-4 col-sm-6 px-2 mb-4" : cardClass ="col-md-4 col-sm-6 px-2 mb-4")
    return (
        <Fragment>
            <div key={character.id} className={cardClass} >
            <div className="card product-card">
                {/*<button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button>*/}
                <Link className="card-img-top d-block overflow-hidden" to={character.url}>
                
                    
                    <img className="image-fit-width" src={character.pictures.thumbnail} alt={character.name.shortName} />
                </Link>
                <div className="card-body py-2">
                    <Stack direction="horizontal" gap={2}>
                {character.badge.map((badge) => {
                        return(<Badge pill key={badge.id} bg={badge.classText}>{badge.badgeText}</Badge>)
                    })}
                    </Stack>
                    <a className="product-meta d-block fs-xs pb-1" href={character.url}>{character.subtitle}</a>
                    <h3 className="product-title fs-sm"><Link href={character.url}>{character.name.longName}</Link></h3>
                    <div className="d-flex justify-content-between">
                        <label className="m-1 form-option-label rounded-circle"><i className={character.genre.icon}></i></label> 
                        {/*<div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-half active"></i><i className="star-rating-icon ci-star"></i></div>*/}
                        
                    </div>

                </div>
                <div className="card-body card-body-hidden">
                    {/*
                                    <div className="text-center pb-2">
                                        <div className="form-check form-option form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="size1" id="s-75" />
                                            <label className="form-option-label" for="s-75">7.5</label>
                                        </div>
                                        <div className="form-check form-option form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="size1" id="s-80" checked />
                                            <label className="form-option-label" for="s-80">8</label>
                                        </div>
                                        <div className="form-check form-option form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="size1" id="s-85" />
                                            <label className="form-option-label" for="s-85">8.5</label>
                                        </div>
                                        <div className="form-check form-option form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="size1" id="s-90" />
                                            <label className="form-option-label" for="s-90">9</label>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-sm d-block w-100 mb-2" type="button"><i className="ci-cart fs-sm me-1"></i>Add to Cart</button>
                                    */}
                    <div className="d-flex justify-content-between">
                        <div className="product-price"><span className="">{character.race}</span></div>
                    </div><div className="d-flex justify-content-between">
                        <div className="product-price"><span className="">{character.gender.name}</span></div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="product-price"><span className="">Age: {character.age.age}</span></div>
                        <div className="product-price"><span className="">Grade: {character.age.grade}</span></div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="product-price"><span className="">Hair: {character.hair}</span></div>
                        <div className="product-price"><span className="">Eyes: {character.eyes}</span></div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="product-price"><span className="">Height(adult):</span></div>
                        <div className="product-price"><span className="">{character.height}</span></div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="product-price"><span className="">Weight(adult):</span></div>
                        <div className="product-price"><span className="">{character.weight}</span></div>
                    </div>


                    {/*<div className="text-center"><a className="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal"><i className="ci-eye align-middle me-1"></i>Quick view</a></div>*/}
                </div>
            </div>
            <hr className="d-sm-none" />
        </div>
        </Fragment>
    )
}

export default SharedComponentCard;