import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import PartsMinisTitle from "../../parts/components/minis/parts.minis.title/parts.minis.title";
import PartsModelGallery from "../../parts/components/parts.components.model.gallery/parts.components.model.gallery";
import Accordion from 'react-bootstrap/Accordion';
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import PartsModelGenreSelect from "../../parts/components/parts.components.model.genreselect/parts.compoenents.model.genreselect";
import PartsModelEditCharLink from "../../parts/components/parts.components.model.editcharLink/parts.components.model.editcharLink";
import PartsMinisButton from "../../parts/components/minis/parts.minis.button/parts.minis.button";
import PartsMinisFormInput from "../../parts/components/minis/parts.minis.formInput/parts.minis.formInput";
import { Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import PartsIdeaEditIdeaLink from "../../parts/components/parts.components.idea.editideaLink/parts.components.idea.editideaLink";


const RouteIdeaClass = () => {
    let { ideaId } = useParams();

    const [idea, setIdea] = useState([]);
    const { mt } = useContext(UserContext);
    let cleanCardText = [];
    let cleanCardTextCount = 0;
    //console.log(topCharacters);

    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        fetch("https://api.liltiffyrps.com/getThisSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&lookupType=getThisIdeaSearch&version=1&test=0&aid=${uid}&sid=${ideaId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.status_code)
                switch (users.status_code) {
                    case 200:
                        setIdea(users.body)
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, ideaId]);

    //console.log(character);
    return (
        <Fragment>

            {idea?.map((thisIdea) => {
                cleanCardText = thisIdea.cardText.split('<br>');
                return (
                    <div key={thisIdea.id}>
                    
                        <PartsMinisTitle title={thisIdea.name} midText="Story Ideas" midLink="/search/ideas" hasMid={true}></PartsMinisTitle>
                    
                        <Container>
                            <section className="row g-0 mx-n2 pb-5 mb-xl-3">
                                <div className="col-xl-7 px-2 mb-3">
                                    <div className="h-100 bg-light rounded-3 p-4">
                                        <img src={thisIdea.pictures.thumbnail} />
                                    </div>
                                </div>
                                <div className="col-xl-5 px-2 mb-3">
                                    <PartsIdeaEditIdeaLink mt={mt} sid={ideaId} status_state={thisIdea.status.state}></PartsIdeaEditIdeaLink>
                                    <div className="h-100 bg-light rounded-3 py-5 px-4 px-sm-5">
                                        <a className="product-meta d-block fs-sm pb-2" href="#"><i className={thisIdea.genre.icon}></i> {thisIdea.genre.name}</a>
                                        <h1 className="h2">{thisIdea.name}</h1>
                                        <div className="h4 fw-normal text-accent">{thisIdea.subtitle}</div>
                                        <h6>Story Idea:</h6>
                                        <div className="fs-sm mb-4">
                                            {cleanCardText?.map((cardTextSegment) => {
                                                return (<p key={cleanCardTextCount} className="text-muted" id="colorOption">{cardTextSegment}</p>);
                                                cleanCardTextCount++;
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </Container>
                    </div>
                )
            })}
        </Fragment>
    )

}

export default RouteIdeaClass;