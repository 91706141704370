export const SchemaTimeLineLight = {
    rps3DateTime: "2023-12-01T09:45",
    rps3CardText: "",
    rps3RoleplayKey: 0,
    rps3PlaceKey: 0,
    
}

export const SchemaTimeLineFull = {

    rps3Id: 0,
    rps3DateTime: "2023-12-01T09:45",
    rps3CardText: "",
    rps3RoleplayKey: 0,
    rps3PlaceKey: 0,
    rps3RealDateTime: "2023-12-01T09:45",
    
}
