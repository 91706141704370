export const SchemaLogLight = {
    
    rps5TimelineKey: 0,
    rps5CardText: "",
    
}

export const SchemaLogFull = {

    rps5Id: 0,
    rps5TimelineKey: 0,
    rps5CardText: "",
        
}