import Form from 'react-bootstrap/Form';

const CreateFormGroupReadOnly = ({ id, value, title, helpText }) => {
    return (
        <Form.Group className="mb-3" style={{ color: "black" }}>
            <Form.Label column sm="2">
                {title}
            </Form.Label>
            <Form.Control type="number" placeholder={value} readOnly />
            <Form.Text id={`${title}HelpBlock`} >
                {helpText}
            </Form.Text>
        </Form.Group>
    )
}

export default CreateFormGroupReadOnly;