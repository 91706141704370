import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import PartsMinisTitle from "../../parts/components/minis/parts.minis.title/parts.minis.title";
import PartsRoleplayTimeLine from "../../parts/components/parts.components.roleplay.timeline/parts.components.roleplay.timeline";
import PartsRoleplayCast from "../../parts/components/parts.components.roleplay.cast/parts.components.roleplay.cast";

const RoutesRoleplayClass = () => {
    let { rpId } = useParams();

    const [rp, setRp] = useState([]);
    const { mt } = useContext(UserContext);
    const { currentUser } = useContext(UserContext);
    let cleanCardText = "";
    let cleanCardTextCount = 0;

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/roleplayDomain/V2/getRPSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&rid=${rpId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setRp(users.body);
            })


    }, [currentUser, rpId]);

    console.log(rp);

    return (
        <Fragment>
            {rp?.map((thisRp) => {
                cleanCardText = thisRp.cardText.split('<br>');
                cleanCardTextCount = 0;

                return (<Fragment key={thisRp.id}>
                    <div>

                        <PartsMinisTitle title={`${thisRp.user}'s ${thisRp.name}`} midText='My Roleplays' midLink='/myroleplays' hasMid={true} />

                        <div className="container mb-5 pb-3">
                            <div className="bg-light shadow-lg rounded-3 overflow-hidden">
                                <div className="row">
                                    {/* Sidebar */}
                                    <aside className="col-lg-4 pe-xl-5">
                                        <div className="bg-white h-100 border-end p-4">
                                            <div className="p-2">
                                                <img src={thisRp.thumbnail} /><br /><hr /><br />
                                                <h6>{thisRp.subtitle}</h6>
                                                {cleanCardText?.map((cardTextSegment) => {
                                                    cleanCardTextCount++;
                                                    return (<p key={cleanCardTextCount} className="text-muted" id="colorOption">{cardTextSegment}</p>);

                                                })
                                                }
                                                
                                            </div>
                                            <p><b>Genre: </b><i className={thisRp.genre.icon}></i> {thisRp.genre.name}</p>
                                            <p><b>Main Character: </b>{thisRp.male.name} (Age: {thisRp.male.age})</p>
                                            <p><b>Female Age Adjustment: </b>X={thisRp.fage}</p>
                                            
                                        </div>
                                    </aside>
                                    <section className="col-lg-8 pt-lg-4 pb-md-4">
                                        <PartsRoleplayCast rpId={rpId}></PartsRoleplayCast>
                                        <div className="pt-2 px-4 ps-lg-0 pe-xl-5">
                                            
                                        </div>
                                    </section>
                                </div>
                            </div>

                            <PartsRoleplayTimeLine rpId={rpId}></PartsRoleplayTimeLine>

                        </div>
                    </div>


                </Fragment>)
            })}
        </Fragment>
    )
}

export default RoutesRoleplayClass;