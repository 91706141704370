import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import PartsModelTitle from "../../parts/components/parts.components.model.title/parts.components.model.title";
import PartsModelGallery from "../../parts/components/parts.components.model.gallery/parts.components.model.gallery";
import Accordion from 'react-bootstrap/Accordion';
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import PartsModelGenreSelect from "../../parts/components/parts.components.model.genreselect/parts.compoenents.model.genreselect";
import PartsModelEditCharLink from "../../parts/components/parts.components.model.editcharLink/parts.components.model.editcharLink";
import PartsMinisButton from "../../parts/components/minis/parts.minis.button/parts.minis.button";
import PartsMinisFormInput from "../../parts/components/minis/parts.minis.formInput/parts.minis.formInput";
import { Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

const defaultUnplannedFormFields = {
    first: "",
    nick: "",
    last: "",
    age: 0,
    pubertyS: 8,
    pubertyE: 14,
}

const RouteModelClass = () => {
    let { modelId } = useParams();
    let characaterFound = false;

    const emptyCharacter = {
        name: {
            longName: "Character Not Found"
        }
    }
    const [character, setCharacter] = useState([]);
    const { mt } = useContext(UserContext);
    const [formfields, setFormFields] = useState(defaultUnplannedFormFields);
    const { first, nick, last, age, pubertyS, pubertyE } = formfields;
    let cleanCardText = [];
    let cleanCardTextCount = 0;
    //console.log(topCharacters);

    if (character === null) { setCharacter(emptyCharacter) }
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        fetch("https://api.liltiffyrps.com/modelDomain/V2/getModelSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&lookupType=characterSearch&version=1&test=0&aid=${uid}&cid=${modelId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.status_code)
                switch (users.status_code) {
                    case 200:
                        setCharacter(users.body)
                        characaterFound = true;
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, modelId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formfields, [name]: value });
        //console.log(formfields);
    };

    const resetFormFields = () => {
        setFormFields(defaultUnplannedFormFields);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }

            fetch("https://api.liltiffyrps.com/modelDomain/V2/setModelToPipeline", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&unFirst=${first}&unNick=${nick}&unLast=${last}&unGrade=${age}&unPubertyS=${pubertyS}&unPubertyE=${pubertyE}`
            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            resetFormFields();
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };

    //console.log(character);
    return (
        <Fragment>

            {character.map((thisCharacter) => {
                cleanCardText = thisCharacter.cardText.split('<br>');
                return (
                    <div key={thisCharacter.id}><div className="page-title-overlap bg-dark pt-4">
                        <PartsModelTitle thisCharacter={thisCharacter} />
                    </div>

                        <div className="container">
                            {/* Gallery + details*/}
                            <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
                                <div className="px-lg-3">
                                    <div className="row">
                                        <PartsModelGallery pid={thisCharacter.keys.pid} />
                                        {/* Product details*/}
                                        <div className="col-lg-5 pt-4 pt-lg-0">
                                            <div className="product-details ms-auto pb-3">
                                                {/*
                                                    <div className="d-flex justify-content-between align-items-center mb-2"><a href="#reviews" data-scroll>
                                                        <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i>
                                                        </div><span className="d-inline-block fs-sm text-body align-middle mt-1 ms-1">74 Reviews</span></a>
                                                        <button className="btn-wishlist me-0 me-lg-n3" type="button" data-bs-toggle="tooltip" title="Add to wishlist"><i className="ci-heart"></i></button>
                                                    </div>
                                                */}
                                                <div className="mb-3">
                                                    <PartsModelEditCharLink mt={mt} cid={modelId} status_state={thisCharacter.status.state}></PartsModelEditCharLink>
                                                    <span className="h4 fw-normal text-dark me-1">{thisCharacter.subtitle}</span>
                                                    <Stack direction="horizontal" gap={2}>
                                                        {thisCharacter.badge.map((badge) => {
                                                            return (<Badge pill key={badge.id} bg={badge.classText}>{badge.badgeText}</Badge>)
                                                        })}
                                                    </Stack>
                                                    
                                                </div>
                                                <div className="fs-sm mb-4">
                                                    {cleanCardText?.map((cardTextSegment) => {
                                                        cleanCardTextCount++;
                                                        return (<p key={cleanCardTextCount} className="text-muted" id="colorOption">{cardTextSegment}</p>);

                                                    })
                                                    }



                                                </div>


                                                <div className="position-relative me-n4 mb-3">
                                                    <PartsModelGenreSelect cid={thisCharacter.keys.cid} mid={thisCharacter.keys.mid}></PartsModelGenreSelect>


                                                    <div className="product-badge product-available mt-n1"><i className={thisCharacter.genre.icon}></i>{thisCharacter.genre.name}</div>

                                                </div>

                                                {/*
                                                <form className="mb-grid-gutter" method="post">
                                                    <div className="mb-3">
                                                        <div className="d-flex justify-content-between align-items-center pb-1">
                                                            <label className="form-label" for="product-size">Size:</label><a className="nav-link-style fs-sm" href="#size-chart" data-bs-toggle="modal"><i className="ci-ruler lead align-middle me-1 mt-n1"></i>Size guide</a>
                                                        </div>
                                                        <select className="form-select" required id="product-size">
                                                            <option value="">Select size</option>
                                                            <option value="xs">XS</option>
                                                            <option value="s">S</option>
                                                            <option value="m">M</option>
                                                            <option value="l">L</option>
                                                            <option value="xl">XL</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <select className="form-select me-3" style={{ width: 5 + 'rem' }}>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                        </select>
                                                        <button className="btn btn-primary btn-shadow d-block w-100" type="submit"><i className="ci-cart fs-lg me-2"></i>Add to Cart</button>
                                                    </div>
                                                </form>
                                                */}
                                                {/* Product panels*/}
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>General Information</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className="fs-sm ps-4">
                                                                <li>{thisCharacter.race}</li>
                                                                <li>{thisCharacter.gender.name}</li>
                                                                <li>Birthday (m/d): {thisCharacter.age.birthMonth}/{thisCharacter.age.birthDay}</li>
                                                                <li>Age: {thisCharacter.age.age}</li>
                                                                <li>Grade: {thisCharacter.age.grade}</li>
                                                            </ul>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Physical Information</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul className="fs-sm ps-4">
                                                                <li>Hair: {thisCharacter.hair}</li>
                                                                <li>Eyes: {thisCharacter.eyes}</li>
                                                                <li>Height (At 18): {thisCharacter.height}</li>
                                                                <li>Weight (At 18): {thisCharacter.weight}</li>
                                                                <li>Measurements (At 18): {thisCharacter.measurements}</li>
                                                            </ul>


                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                </Accordion>


                                                {/* Sharing*/}
                                                {/*<label className="form-label d-inline-block align-middle my-2 me-3">Share:</label><a className="btn-share btn-twitter me-2 my-2" href="#"><i className="ci-twitter"></i>Twitter</a><a className="btn-share btn-instagram me-2 my-2" href="#"><i className="ci-instagram"></i>Instagram</a><a className="btn-share btn-facebook my-2" href="#"><i className="ci-facebook"></i>Facebook</a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Product description section 1*/}
                            {/*
                            <div className="row align-items-center py-md-3">
                                <div className="col-lg-5 col-md-6 offset-lg-1 order-md-2"><img className="d-block rounded-3" src="img/shop/single/prod-img.jpg" alt="Image" /></div>
                                <div className="col-lg-4 col-md-6 offset-lg-1 py-4 order-md-1">
                                    <h2 className="h3 mb-4 pb-2">High quality materials</h2>
                                    <h6 className="fs-base mb-3">Soft cotton blend</h6>
                                    <p className="fs-sm text-muted pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit.</p>
                                    <h6 className="fs-base mb-3">Washing instructions</h6>
                                    <ul className="nav nav-tabs mb-3" role="tablist">
                                        <li className="nav-item"><a className="nav-link active" href="#wash" data-bs-toggle="tab" role="tab"><i className="ci-wash fs-xl"></i></a></li>
                                        <li className="nav-item"><a className="nav-link" href="#bleach" data-bs-toggle="tab" role="tab"><i className="ci-bleach fs-xl"></i></a></li>
                                        <li className="nav-item"><a className="nav-link" href="#hand-wash" data-bs-toggle="tab" role="tab"><i className="ci-hand-wash fs-xl"></i></a></li>
                                        <li className="nav-item"><a className="nav-link" href="#ironing" data-bs-toggle="tab" role="tab"><i className="ci-ironing fs-xl"></i></a></li>
                                        <li className="nav-item"><a className="nav-link" href="#dry-clean" data-bs-toggle="tab" role="tab"><i className="ci-dry-clean fs-xl"></i></a></li>
                                    </ul>
                                    <div className="tab-content text-muted fs-sm">
                                        <div className="tab-pane fade show active" id="wash" role="tabpanel">30° mild machine washing</div>
                                        <div className="tab-pane fade" id="bleach" role="tabpanel">Do not use any bleach</div>
                                        <div className="tab-pane fade" id="hand-wash" role="tabpanel">Hand wash normal (30°)</div>
                                        <div className="tab-pane fade" id="ironing" role="tabpanel">Low temperature ironing</div>
                                        <div className="tab-pane fade" id="dry-clean" role="tabpanel">Do not dry clean</div>
                                    </div>
                                </div>
                            </div>
                            */}
                            {/* Product description section 2*/}
                            {/*
                            <div className="row align-items-center py-4 py-lg-5">
                                <div className="col-lg-5 col-md-6 offset-lg-1"><img className="d-block rounded-3" src="img/shop/single/prod-map.png" alt="Map" /></div>
                                <div className="col-lg-4 col-md-6 offset-lg-1 py-4">
                                    <h2 className="h3 mb-4 pb-2">Where is it made?</h2>
                                    <h6 className="fs-base mb-3">Apparel Manufacturer, Ltd.</h6>
                                    <p className="fs-sm text-muted pb-2">​Sam Tower, 6 Road No 32, Dhaka 1875, Bangladesh</p>
                                    <div className="d-flex mb-2">
                                        <div className="me-4 pe-2 text-center">
                                            <h4 className="h2 text-accent mb-1">3258</h4>
                                            <p>Workers</p>
                                        </div>
                                        <div className="me-4 pe-2 text-center">
                                            <h4 className="h2 text-accent mb-1">43%</h4>
                                            <p>Female</p>
                                        </div>
                                        <div className="text-center">
                                            <h4 className="h2 text-accent mb-1">57%</h4>
                                            <p>Male</p>
                                        </div>
                                    </div>
                                    <h6 className="fs-base mb-3">Factory information</h6>
                                    <p className="fs-sm text-muted pb-md-2">​Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                </div>
                            </div>
                        */}
                        </div>


                        {/* Reviews*/}
                        {/*
                        <div className="border-top border-bottom my-lg-3 py-5">
                            <div className="container pt-md-2" id="reviews">
                                <div className="row pb-3">
                                    <div className="col-lg-4 col-md-5">
                                        <h2 className="h3 mb-4">74 Reviews</h2>
                                        <div className="star-rating me-2"><i className="ci-star-filled fs-sm text-accent me-1"></i><i className="ci-star-filled fs-sm text-accent me-1"></i><i className="ci-star-filled fs-sm text-accent me-1"></i><i className="ci-star-filled fs-sm text-accent me-1"></i><i className="ci-star fs-sm text-muted me-1"></i></div><span className="d-inline-block align-middle">4.1 Overall rating</span>
                                        <p className="pt-3 fs-sm text-muted">58 out of 74 (77%)<br />Customers recommended this product</p>
                                    </div>
                                    <div className="col-lg-8 col-md-7">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="text-nowrap me-3"><span className="d-inline-block align-middle text-muted">5</span><i className="ci-star-filled fs-xs ms-1"></i></div>
                                            <div className="w-100">
                                                <div className="progress" style={{ height: 4 + 'px' }}>
                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: 60 + '%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div><span className="text-muted ms-3">43</span>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="text-nowrap me-3"><span className="d-inline-block align-middle text-muted">4</span><i className="ci-star-filled fs-xs ms-1"></i></div>
                                            <div className="w-100">
                                                <div className="progress" style={{ height: 4 + 'px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: 27 + '%', backgroundColor: '#a7e453' }} aria-valuenow="27" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div><span className="text-muted ms-3">16</span>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="text-nowrap me-3"><span className="d-inline-block align-middle text-muted">3</span><i className="ci-star-filled fs-xs ms-1"></i></div>
                                            <div className="w-100">
                                                <div className="progress" style={{ height: 4 + 'px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: 17 + '%', backgroundColor: '#ffda75' }} aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div><span className="text-muted ms-3">9</span>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="text-nowrap me-3"><span className="d-inline-block align-middle text-muted">2</span><i className="ci-star-filled fs-xs ms-1"></i></div>
                                            <div className="w-100">
                                                <div className="progress" style={{ height: 4 + 'px' }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: 9 + '%', backgroundColor: '#fea569' }} aria-valuenow="9" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div><span className="text-muted ms-3">4</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="text-nowrap me-3"><span className="d-inline-block align-middle text-muted">1</span><i className="ci-star-filled fs-xs ms-1"></i></div>
                                            <div className="w-100">
                                                <div className="progress" style={{ height: 4 + 'px' }}>
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: 4 + '%' }} aria-valuenow="4" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div><span className="text-muted ms-3">2</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-4 mb-3" />
                                <div className="row pt-4">
                                    {/* Reviews list*/}
                        {/*
                                    <div className="col-md-7">
                                        <div className="d-flex justify-content-end pb-4">
                                            <div className="d-flex align-items-center flex-nowrap">
                                                <label className="fs-sm text-muted text-nowrap me-2 d-none d-sm-block" for="sort-reviews">Sort by:</label>
                                                <select className="form-select form-select-sm" id="sort-reviews">
                                                    <option>Newest</option>
                                                    <option>Oldest</option>
                                                    <option>Popular</option>
                                                    <option>High rating</option>
                                                    <option>Low rating</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* Review*/}
                        {/*
                                        <div className="product-review pb-4 mb-4 border-bottom">
                                            <div className="d-flex mb-3">
                                                <div className="d-flex align-items-center me-4 pe-2"><img className="rounded-circle" src="img/shop/reviews/01.jpg" width="50" alt="Rafael Marquez" />
                                                    <div className="ps-3">
                                                        <h6 className="fs-sm mb-0">Rafael Marquez</h6><span className="fs-ms text-muted">June 28, 2019</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                    <div className="fs-ms text-muted">83% of users found this review helpful</div>
                                                </div>
                                            </div>
                                            <p className="fs-md mb-2">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est...</p>
                                            <ul className="list-unstyled fs-ms pt-1">
                                                <li className="mb-1"><span className="fw-medium">Pros:&nbsp;</span>Consequuntur magni, voluptatem sequi, tempora</li>
                                                <li className="mb-1"><span className="fw-medium">Cons:&nbsp;</span>Architecto beatae, quis autem</li>
                                            </ul>
                                            <div className="text-nowrap">
                                                <button className="btn-like" type="button">15</button>
                                                <button className="btn-dislike" type="button">3</button>
                                            </div>
                                        </div>
                                        {/* Review*/}
                        {/*
                                        <div className="product-review pb-4 mb-4 border-bottom">
                                            <div className="d-flex mb-3">
                                                <div className="d-flex align-items-center me-4 pe-2"><img className="rounded-circle" src="img/shop/reviews/02.jpg" width="50" alt="Barbara Palson" />
                                                    <div className="ps-3">
                                                        <h6 className="fs-sm mb-0">Barbara Palson</h6><span className="fs-ms text-muted">May 17, 2019</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i>
                                                    </div>
                                                    <div className="fs-ms text-muted">99% of users found this review helpful</div>
                                                </div>
                                            </div>
                                            <p className="fs-md mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <ul className="list-unstyled fs-ms pt-1">
                                                <li className="mb-1"><span className="fw-medium">Pros:&nbsp;</span>Consequuntur magni, voluptatem sequi, tempora</li>
                                                <li className="mb-1"><span className="fw-medium">Cons:&nbsp;</span>Architecto beatae, quis autem</li>
                                            </ul>
                                            <div className="text-nowrap">
                                                <button className="btn-like" type="button">34</button>
                                                <button className="btn-dislike" type="button">1</button>
                                            </div>
                                        </div>
                                        {/* Review*/}
                        {/*
                                        <div className="product-review pb-4 mb-4 border-bottom">
                                            <div className="d-flex mb-3">
                                                <div className="d-flex align-items-center me-4 pe-2"><img className="rounded-circle" src="img/shop/reviews/03.jpg" width="50" alt="Daniel Adams" />
                                                    <div className="ps-3">
                                                        <h6 className="fs-sm mb-0">Daniel Adams</h6><span className="fs-ms text-muted">May 8, 2019</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                    <div className="fs-ms text-muted">75% of users found this review helpful</div>
                                                </div>
                                            </div>
                                            <p className="fs-md mb-2">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.</p>
                                            <ul className="list-unstyled fs-ms pt-1">
                                                <li className="mb-1"><span className="fw-medium">Pros:&nbsp;</span>Consequuntur magni, voluptatem sequi</li>
                                                <li className="mb-1"><span className="fw-medium">Cons:&nbsp;</span>Architecto beatae,  quis autem, voluptatem sequ</li>
                                            </ul>
                                            <div className="text-nowrap">
                                                <button className="btn-like" type="button">26</button>
                                                <button className="btn-dislike" type="button">9</button>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-outline-accent" type="button"><i className="ci-reload me-2"></i>Load more reviews</button>
                                        </div>
                                    </div>
                                    {/* Leave review form*/}
                        {/*
                                    <div className="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
                                        <div className="bg-secondary py-grid-gutter px-grid-gutter rounded-3">
                                            <h3 className="h4 pb-2">Write a review</h3>
                                            <form className="needs-validation" method="post" novalidate>
                                                <div className="mb-3">
                                                    <label className="form-label" for="review-name">Your name<span className="text-danger">*</span></label>
                                                    <input className="form-control" type="text" required id="review-name" />
                                                    <div className="invalid-feedback">Please enter your name!</div><small className="form-text text-muted">Will be displayed on the comment.</small>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" for="review-email">Your email<span className="text-danger">*</span></label>
                                                    <input className="form-control" type="email" required id="review-email" />
                                                    <div className="invalid-feedback">Please provide valid email address!</div><small className="form-text text-muted">Authentication only - we won't spam you.</small>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" for="review-rating">Rating<span className="text-danger">*</span></label>
                                                    <select className="form-select" required id="review-rating">
                                                        <option value="">Choose rating</option>
                                                        <option value="5">5 stars</option>
                                                        <option value="4">4 stars</option>
                                                        <option value="3">3 stars</option>
                                                        <option value="2">2 stars</option>
                                                        <option value="1">1 star</option>
                                                    </select>
                                                    <div className="invalid-feedback">Please choose rating!</div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" for="review-text">Review<span className="text-danger">*</span></label>
                                                    <textarea className="form-control" rows="6" required id="review-text"></textarea>
                                                    <div className="invalid-feedback">Please write a review!</div><small className="form-text text-muted">Your review must be at least 50 characters.</small>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" for="review-pros">Pros</label>
                                                    <textarea className="form-control" rows="2" placeholder="Separated by commas" id="review-pros"></textarea>
                                                </div>
                                                <div className="mb-3 mb-4">
                                                    <label className="form-label" for="review-cons">Cons</label>
                                                    <textarea className="form-control" rows="2" placeholder="Separated by commas" id="review-cons"></textarea>
                                                </div>
                                                <button className="btn btn-primary btn-shadow d-block w-100" type="submit">Submit a Review</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    */}
                        {/* Product carousel (Style with)*/}
                        {/*
                        <div className="container pt-5">
                            <h2 className="h3 text-center pb-4">Style with</h2>
                            <div className="tns-carousel tns-controls-static tns-controls-outside">
                                <div className="tns-carousel-inner" data-carousel-options="{&quot;items&quot;: 2, &quot;controls&quot;: true, &quot;nav&quot;: false, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1},&quot;500&quot;:{&quot;items&quot;:2, &quot;gutter&quot;: 18},&quot;768&quot;:{&quot;items&quot;:3, &quot;gutter&quot;: 20}, &quot;1100&quot;:{&quot;items&quot;:4, &quot;gutter&quot;: 30}}}">
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/14.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Jeans</a>
                                                <h3 className="product-title fs-sm"><a href="#">Slim Taper Fit Jeans</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price"><span className="text-accent">$58.<small>99</small></span></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static"><span className="badge bg-danger badge-shadow">Sale</span>
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/17.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s T-shirts</a>
                                                <h3 className="product-title fs-sm"><a href="#">Cotton T-shirt Regular Fit</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price"><span className="text-accent">$9.<small>50</small></span>
                                                        <del className="fs-sm text-muted">$11.<small>50</small></del>
                                                    </div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-half active"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/18.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Shoes</a>
                                                <h3 className="product-title fs-sm"><a href="#">Men’s Leather Keds</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$64.<small>99</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/19.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s T-shirts</a>
                                                <h3 className="product-title fs-sm"><a href="#">3 Color Collection of T-shirts</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$27.<small>99</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-half active"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/13.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s T-shirts</a>
                                                <h3 className="product-title fs-sm"><a href="#">Cotton Polo Regular Fit</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$13.<small>50</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    */}

                        {/* Product carousel (You may also like)*/}{/*
                        <div className="container py-5 my-md-3">
                            <h2 className="h3 text-center pb-4">You may also like</h2>
                            <div className="tns-carousel tns-controls-static tns-controls-outside">
                                <div className="tns-carousel-inner" data-carousel-options="{&quot;items&quot;: 2, &quot;controls&quot;: true, &quot;nav&quot;: false, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1},&quot;500&quot;:{&quot;items&quot;:2, &quot;gutter&quot;: 18},&quot;768&quot;:{&quot;items&quot;:3, &quot;gutter&quot;: 20}, &quot;1100&quot;:{&quot;items&quot;:4, &quot;gutter&quot;: 30}}}">
                                
                                {/*
                                {thisCharacter.groups.groupslist[0].members.map((character) => {
                        return (
                            <SharedComponentCard character={character} page ="home" key={character.id} />
                        )
                    })}*/}

                        {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/20.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Hoodie</a>
                                                <h3 className="product-title fs-sm"><a href="#">Block-colored Hooded Top</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price"><span className="text-accent">$24.<small>99</small></span></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-half active"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/21.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Hoodie</a>
                                                <h3 className="product-title fs-sm"><a href="#">Block-colored Hooded Top</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$26.<small>99</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/22.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Hoodie</a>
                                                <h3 className="product-title fs-sm"><a href="#">Block-colored Hooded Top</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$24.<small>99</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-half active"></i><i className="star-rating-icon ci-star"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/23.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Hoodie</a>
                                                <h3 className="product-title fs-sm"><a href="#">Block-colored Hooded Top</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$24.<small>99</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Product*/}{/*
                                    <div>
                                        <div className="card product-card card-static">
                                            <button className="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i className="ci-heart"></i></button><a className="card-img-top d-block overflow-hidden" href="#"><img src="img/shop/catalog/24.jpg" alt="Product" /></a>
                                            <div className="card-body py-2"><a className="product-meta d-block fs-xs pb-1" href="#">Men’s Hoodie</a>
                                                <h3 className="product-title fs-sm"><a href="#">Block-colored Hooded Top</a></h3>
                                                <div className="d-flex justify-content-between">
                                                    <div className="product-price text-accent">$25.<small>00</small></div>
                                                    <div className="star-rating"><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star-filled active"></i><i className="star-rating-icon ci-star"></i><i className="star-rating-icon ci-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    */}

                        {(mt == 'a' || mt == 'm') && thisCharacter.status.state === "unplanned" ?
                            <div>
                                <Container>
                                    <form onSubmit={handleSubmit}>
                                        {/*First Name, Nick Name, Last Name, Age, Puberty Start, Puberty End,    */}
                                        <p>Please enter the full first name of the character:</p>
                                        <PartsMinisFormInput label="First Name" type="text" required onChange={handleChange} name="first" value={first} />
                                        <p>Please enter the nick name of the character.  If none, leave blank.</p>
                                        <PartsMinisFormInput label="Nick Name" type="text" required onChange={handleChange} name="nick" value={nick} />
                                        <p>Please enter the last name of the character.  This would be the actual last name, reguardless of the family name.  Note: family logic is not implemented yet in v8.</p>
                                        <PartsMinisFormInput label="Last Name" type="text" required onChange={handleChange} name="last" value={last} />
                                        <p>Please enter the grade of the character.  This will be used to factor the age.</p>
                                        <PartsMinisFormInput label="Grade" type="number" required onChange={handleChange} name="age" value={age} />
                                        <p>Please enter how old the character is when the start puberty.  This is used to help determine character stats at various ages.</p>
                                        <PartsMinisFormInput label="Puberty Start Age" type="number" required onChange={handleChange} name="pubertyS" value={pubertyS} />
                                        <p>Please enter how old the character is when the start ends.  This is used to help determine character stats at various ages.</p>
                                        <PartsMinisFormInput label="Puberty End Age" type="number" required onChange={handleChange} name="pubertyE" value={pubertyE} />



                                        <div className="signIn-button-container">
                                            <PartsMinisButton type="sumbit">Move to Pipeline</PartsMinisButton>
                                            {/*<PartsMinisButton type="button" onClick={SignInWithGoogle} buttonType="google">Google Sign In</PartsMinisButton>*/}
                                        </div>
                                    </form>


                                </Container>
                            </div>

                            : ""}


                    </div>
                )
            })}





        </Fragment>
    )

}

export default RouteModelClass;