import { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/user.context";
import PartsModelTitle from "../../parts/components/parts.components.model.title/parts.components.model.title";
import PartsModelGallery from "../../parts/components/parts.components.model.gallery/parts.components.model.gallery";
import Accordion from 'react-bootstrap/Accordion';
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import PartsModelGenreSelect from "../../parts/components/parts.components.model.genreselect/parts.compoenents.model.genreselect";
import PartsModelEditCharLink from "../../parts/components/parts.components.model.editcharLink/parts.components.model.editcharLink";
import PartsMinisButton from "../../parts/components/minis/parts.minis.button/parts.minis.button";
import PartsMinisFormInput from "../../parts/components/minis/parts.minis.formInput/parts.minis.formInput";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CreateFormGroupText from "../../parts/components/minis/parts.minis.formPieces/parts.minis.textbox";
import CreateFormGroupArea from "../../parts/components/minis/parts.minis.formPieces/parts.minis.textarea";
import CreateFormGroupNumber from "../../parts/components/minis/parts.minis.formPieces/parts.minis.numberbox";
import CreateFormGroupReadOnly from "../../parts/components/minis/parts.minis.formPieces/parts.minis.readonly";

const defaultIdeaFormFields = {

    str2CardText: "",
    str2Name: "",
    str2Subtitle: "",
    str2Cover: "",
    str2RpNotes: "",
    str2SourceName: "",
    str2Genre: 1,

}

const RouteNewIdeaClass = () => {
    let { ideaId } = useParams();

    const [idea, setIdea] = useState([]);
    const { mt } = useContext(UserContext);

    /*
    const [unplannedFormfields, setUnplannedFormFields] = useState(defaultUnplannedFormFields);*/
    const [ideaFormFields, setIdeaFormFields] = useState(defaultIdeaFormFields);
    const { str2CardText, str2Name, str2Subtitle, str2Cover, str2RpNotes, str2SourceName, str2Genre } = ideaFormFields;


    /*
    const { unFirst, unNick, unLast, unGrade, unPubertyS, unPubertyE } = unplannedFormfields;

    const { cha1First, cha1Nick, cha1Last, cha1Grade, cha1PubertyS, cha1PubertyE, cha1BirthMonth, cha1BirthDay, cha1Real, cha1Anime,
        cha2Ageless, cha2CardText, cha2Location, cha2Occupation, cha2Subtitle, cha2Race, cha2AltName, cha2Thumb, } = characterFormfields;*/

    //console.log(topCharacters);

    const { currentUser } = useContext(UserContext);
    const [genres, setGenres] = useState([]);

    /*
    const [pickedGenre, setPickedGenre] = useState([]);
    const [pickedCat, setPickedCat] = useState([]);
    const [cats, setCats] = useState([]);
    const [characterCats, setCharacterCats] = useState([]);
    */

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/modelDomain/V2/getGenreInfo", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                setGenres(users.body);
            })


    }, [currentUser]);

    /*
    const handleUnplannedChange = (event) => {
        const { name, value } = event.target;
        //console.log ("Name: ",name," Value: ",value)
        setUnplannedFormFields({ ...unplannedFormfields, [name]: value });
        //console.log(unplannedFormfields);
    };
    */

    const handleIdeaChange = (event) => {
        const { name, value } = event.target;
        console.log("Name: ", name, "Value: ", value);
        setIdeaFormFields({ ...ideaFormFields, [name]: value });
        //console.log(ideaFormFields);
    };

    /*
        const handleNewGenreChange = (event) => {
            const { value } = event.target;
            setPickedGenre(value);
            console.log(pickedGenre);
        };
    
        const handleNewCatChange = (event) => {
            const { value } = event.target;
            setPickedCat(value);
            console.log(pickedGenre);
        };
    
        const resetFormFields = () => {
            setUnplannedFormFields(defaultUnplannedFormFields);
            setCharacterFormFields(defaultCharacterFormFields);
        }
    
        const handleUnPlannedSubmit = async (event) => {
            event.preventDefault();
    
            try {
                let uid = -1;
                if (currentUser !== null) {
                    uid = currentUser.uid;
                }
    
                fetch("https://api.liltiffyrps.com/modelDomain/V2/setModelToPipeline", {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&first=${unFirst}&nick=${unNick}&last=${unLast}&age=${unGrade}&pubertyS=${unPubertyS}&pubertyE=${unPubertyE}`
    
                }
                )
                    .then(response => response.json())
                    .then(users => {
                        //console.log (users.status_code)
                        switch (users.status_code) {
                            case 200:
                                window.location.reload();
                                break;
                            case 300:
                                //not found
    
                                break;
    
                            case 400:
                            //unauthorized
    
                        }
    
                    })
                //const {user} = await signInAuthUserWithEmailandPassword(email, password);
                resetFormFields();
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        alert('Incorrect Password for Email');
                        break;
                    case 'auth/user-not-found':
                        alert('No user associated with this email');
                        break;
                    default:
                        console.log(error);
                }
    
            }
        };
    
        const handleNewGenreSubmit = async (event) => {
            event.preventDefault();
            
            try {
                let uid = -1;
                if (currentUser !== null) {
                    uid = currentUser.uid;
                }
    
                fetch("https://api.liltiffyrps.com/modelDomain/V2/setNewGenreModel", {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&gid=${pickedGenre}`
    
                }
                )
                    .then(response => response.json())
                    .then(users => {
                        //console.log (users.status_code)
                        switch (users.status_code) {
                            case 200:
                                window.location.href = `/admin/edit/model/${users.body.cid}`;
                                //window.location.reload();
                                break;
                            case 300:
                                //not found
    
                                break;
    
                            case 400:
                            //unauthorized
    
                        }
    
                    })
                //const {user} = await signInAuthUserWithEmailandPassword(email, password);
                resetFormFields();
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        alert('Incorrect Password for Email');
                        break;
                    case 'auth/user-not-found':
                        alert('No user associated with this email');
                        break;
                    default:
                        console.log(error);
                }
    
            }
        };
    
        const handleNewCatSubmit = async (event) => {
            event.preventDefault();
            
            try {
                let uid = -1;
                if (currentUser !== null) {
                    uid = currentUser.uid;
                }
    
                fetch("https://api.liltiffyrps.com/modelDomain/V2/setNewCatForCharacter", {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&catid=${pickedCat}`
    
                }
                )
                    .then(response => response.json())
                    .then(users => {
                        //console.log (users.status_code)
                        switch (users.status_code) {
                            case 200:
                                window.location.reload();
                                break;
                            case 300:
                                //not found
    
                                break;
    
                            case 400:
                            //unauthorized
    
                        }
    
                    })
                //const {user} = await signInAuthUserWithEmailandPassword(email, password);
                resetFormFields();
            } catch (error) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        alert('Incorrect Password for Email');
                        break;
                    case 'auth/user-not-found':
                        alert('No user associated with this email');
                        break;
                    default:
                        console.log(error);
                }
    
            }
        };
    
        */
    const handleEditIdeaSubmit = async (event) => {
        event.preventDefault();
        //console.log(ideaFormFields);

        try {
            let uid = -1;
            if (currentUser !== null) {
                uid = currentUser.uid;
            }
            //const serviceBody = `title=meow&release=1&version=1&test=0&aid=${uid}&cid=${modelId}&cha1First=${cha1First}&cha1Nick=${cha1Nick}&cha1Last=${cha1Last}&cha1Grade=${cha1Grade}&cha1PubertyS=${cha1PubertyS}&cha1PubertyE=${cha1PubertyE}&cha1BirthMonth=${cha1BirthMonth}&cha1BirthDay=${cha1BirthDay}&cha1Real=${cha1Real}&cha1Anime=${cha1Anime}&cha2Ageless=${cha2Ageless}&cha2AltName=${cha2AltName}&cha2CardText=${cha2CardText}&cha2Location=${cha2Location}&cha2Occupation=${cha2Occupation}&cha2Race=${cha2Race}&cha2Subtitle=${cha2Subtitle}&cha2Thumb=${cha2Thumb}`
            //console.log(serviceBody);
            fetch("https://api.liltiffyrps.com/ideaDomain/V2/setNewIdea", {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: `title=meow&release=1&version=1&test=0&aid=${uid}&sid=${ideaId}&str2CardText=${str2CardText}&str2Name=${str2Name}&str2Subtitle=${str2Subtitle}&str2Cover=${str2Cover}&str2RpNotes=${str2RpNotes}&str2SourceName=${str2SourceName}&str2Genre=${str2Genre}`

            }
            )
                .then(response => response.json())
                .then(users => {
                    //console.log (users.status_code)
                    switch (users.status_code) {
                        case 200:
                            window.location.href = `/admin/edit/idea/${users.body.id}`;
                            //window.location.reload();
                            break;
                        case 300:
                            //not found

                            break;

                        case 400:
                        //unauthorized

                    }

                })
            //const {user} = await signInAuthUserWithEmailandPassword(email, password);
            //resetFormFields();

        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    alert('Incorrect Password for Email');
                    break;
                case 'auth/user-not-found':
                    alert('No user associated with this email');
                    break;
                default:
                    console.log(error);
            }

        }
    };


    console.log(idea);
    console.log(ideaFormFields);
    //console.log(character);
    //console.log(characterFormfields);
    //console.log(cats);
    //console.log(characterCats);


    return (
        <Fragment>

            <div className="container" >
                {/* Gallery + details*/}
                <div className="bg-primary shadow-lg rounded-3 px-4 py-3 mb-5">
                    <div className="px-lg-3">
                        <h2 className="text-light text-center">New Story Idea</h2>
                        <p className="bg-danger text-light text-center">Please note that while v8 does introduce new fields, many of these fields are used across versions.  Changes made to these story ideas may impact all site versions and data is not recoverable.</p>
                        <p className="bg-warning text-dark text-center">Clicking "Update Story Idea" under any of the below forms will update the idea based off the data in all forms.  They do not need to be submitted seperatly.  The repeating buttons are only there for convience when making minor updates.</p>

                    </div>
                </div>
                
                
                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                    <div className="px-lg-3">
                        < div className="row">
                            <div>
                                <Container>
                                    <h3 className="text-center">STR2: General Story Idea Information</h3>
                                    <p className="bg-danger text-light text-center"></p>
                                    <Form onSubmit={handleEditIdeaSubmit}>
                                        <CreateFormGroupText
                                            id="str2Name"
                                            isRequired={true}
                                            onChangeHandler={handleIdeaChange}
                                            value={str2Name}
                                            title="Story Idea Name"
                                            helpText="Please enter the story idea name." >
                                        </CreateFormGroupText>
                                        <Form.Group className="mb-3" style={{ color: "black" }}>
                                            <Form.Select onChange={handleIdeaChange} aria-label="Default select example" name="str2Genre" value={str2Genre}>
                                                {genres?.map((genre) => {
                                                    return (
                                                        <Fragment key={genre.id}>
                                                            <option value={genre.id}>{genre.name}</option>
                                                        </Fragment>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                        <CreateFormGroupText
                                            id="str2Subtitle"
                                            isRequired={false}
                                            onChangeHandler={handleIdeaChange}
                                            value={str2Subtitle}
                                            title="Short Description"
                                            helpText="Please enter the short description of the story idea.  This should be a phrase and appears on the story idea card" >
                                        </CreateFormGroupText>
                                        <CreateFormGroupArea
                                            id="str2CardText"
                                            isRequired={false}
                                            onChangeHandler={handleIdeaChange}
                                            value={str2CardText}
                                            title="Long Description"
                                            helpText="Please enter the long description of the story idea."
                                            rows="4" >
                                        </CreateFormGroupArea>
                                        <CreateFormGroupArea
                                            id="str2RpNotes"
                                            isRequired={false}
                                            onChangeHandler={handleIdeaChange}
                                            value={str2RpNotes}
                                            title="Roleplay Description"
                                            helpText="Please enter more technical details for the story idea."
                                            rows="4" >
                                        </CreateFormGroupArea>
                                        <CreateFormGroupText
                                            id="str2Cover"
                                            isRequired={false}
                                            onChangeHandler={handleIdeaChange}
                                            value={str2Cover}
                                            title="Picture File"
                                            helpText="Please enter the filename of the picture for this story idea" >
                                        </CreateFormGroupText>
                                        <CreateFormGroupText
                                            id="str2SourceName"
                                            isRequired={false}
                                            onChangeHandler={handleIdeaChange}
                                            value={str2SourceName}
                                            title="Source Name"
                                            helpText="Please enter name of the source from which the story idea was taken." >
                                        </CreateFormGroupText>
                                        <Button variant="primary" type="submit">
                                            Add Story Idea
                                        </Button>
                                    </Form>

                                </Container>
                            </div>
                        </div>

                    </div>
                </div>{/*
                                <div className="bg-info shadow-lg rounded-3 px-4 py-3 mb-5">
                                    <div className="px-lg-3">
                                        < div className="row">
                                            <div>
                                                <Container>
                                                    <h3 className="text-center">CHA2: Genre Specific Character Information</h3>
                                                    <p className="bg-success text-light text-center">All fields in this section apply to the specific version of the character tied to the genre.</p>
                                                    <Form onSubmit={handleEditCharacterSubmit}>
                                                        <Form.Group className="mb-3" style={{ color: "black" }}>
                                                            <Form.Label column sm="2">
                                                                CiD
                                                            </Form.Label>
                                                            <Form.Control type="number" placeholder={thisCharacter.keys.cid} readOnly />
                                                            <Form.Text id={`CiDHelpBlock`} >
                                                                This is the charcter's genre form key and the primary key to CH2.  In version 8 it is the main key used to access a specific genre character.  It cannot be changed.
                                                            </Form.Text>
                                                        </Form.Group>
                                                        <CreateFormGroupText
                                                            id="cha2AltName"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2AltName}
                                                            title="Genre Specific Name Override"
                                                            helpText="Enter the name of the character here only if it differs from the base character's name.  If the base character's name is used for this genre please leave this field blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Race"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Race}
                                                            title="Race Override"
                                                            helpText="Enter the race of the character here only if it differs from the model's race.  If the model's race is used for this genre please leave this field blank." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Subtitle"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Subtitle}
                                                            title="Short Description"
                                                            helpText="Please enter the short description of the character.  This should be a phrase and appears on the character card" >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupArea
                                                            id="cha2CardText"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2CardText}
                                                            title="Long Description"
                                                            helpText="Please enter the long description of the character."
                                                            rows="4" >
                                                        </CreateFormGroupArea>
                                                        <CreateFormGroupNumber
                                                            id="cha2Ageless"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={parseInt(cha2Ageless)}
                                                            title="Is Ageless"
                                                            helpText="Please enter 0 if the charcter ages normally and 1 if the character does not age, such as robots and goddesses." >
                                                        </CreateFormGroupNumber>
                                                        <CreateFormGroupText
                                                            id="cha2Location"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Location}
                                                            title="Location"
                                                            helpText="Enter the place in which the character lives" >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Occupation"
                                                            isRequired={true}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Occupation}
                                                            title="Occupation"
                                                            helpText="Enter the occupation or fantasy class of the character." >
                                                        </CreateFormGroupText>
                                                        <CreateFormGroupText
                                                            id="cha2Thumb"
                                                            isRequired={false}
                                                            onChangeHandler={handleCharacterChange}
                                                            value={cha2Thumb}
                                                            title="Thumbnail Override"
                                                            helpText="Typically the card thumbnail of the character will be the first picture in the gallery.  Enter a thumbnail picture file name to override that with the one provided for this genre character specifically." >
                                                        </CreateFormGroupText>
                                                        <Button variant="primary" type="submit">
                                                            Update Character
                                                        </Button>
                                                    </Form>
                                                </Container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>

                        } */}

            </div >
            






        </Fragment >
    )

}

export default RouteNewIdeaClass;