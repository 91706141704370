import { Fragment } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const SharedComponentFooter = () => {
    return (
        <Fragment>
            <footer className="footer bg-dark pt-5">
                <Container>
                    <div className="row pb-2">
                        <div className="col-md-4 col-sm-6">
                            <div className="widget widget-links widget-light pb-2 mb-4">
                                <h3 className="widget-title text-light">Site Map</h3>
                                <ul className="widget-list">
                                    <li className="widget-list-item">
                                        <Link className="widget-list-link" to="/">Home</Link>
                                    </li>
                                    <li className="widget-list-item">
                                        <Link className="widget-list-link" to="/models/all">Characters</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pb-4 fs-xs text-light opacity-50 text-center text-md-start">© All rights reserved. Made by LilTiffy. All characters and role-play information is fictional.</div>
                </Container>
            </footer>
        </Fragment>
    )
}

export default SharedComponentFooter;