import React, { Fragment, useContext, useEffect, useState } from "react";
import PartsModelsTitle from "../../parts/components/parts.components.models.title/parts.components.models.title";
import PartsModelsSidebar from "../../parts/components/parts.components.models.sidebar/parts.components.models.sidebar";
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import { UserContext } from "../../contexts/user.context";
import { CharactersContext } from "../../contexts/characters.context";

const RouteModelsClass = () => {

  const { currentUser } = useContext(UserContext);
  const { characters, setCharacters} = useContext(CharactersContext);
  const { filteredCharacters, setFilteredCharacters} = useContext(CharactersContext);
  
  useEffect(() => {
    
    setCharacters([])



  }, [])

  useEffect(() => {
    //console.log(currentUser);
    let uid = -1;
    let response = [];
    if (currentUser !== null) {
      uid = currentUser.uid;
    }

    setCharacters([]);
    setFilteredCharacters([]);
    //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);

    fetch("https://api.liltiffyrps.com/modelDomain/V2/getAllModelsSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
    )
      .then(response => response.json())
      .then(users =>{
        switch(users.status_code){
          case 200:
            setCharacters(users.body)
            setFilteredCharacters(users.body)
            //console.log(characters);
            //console.log(filteredCharacters);
            
          break;

          default:
          setCharacters([])
          setFilteredCharacters([])
          //console.log(characters);
          //console.log(filteredCharacters);
      

          break;
        }
        
        //console.log(users);(
        
      
      })




  }, [currentUser])

  //console.log(characters);
  //console.log(filteredCharacters);

  return (
    <Fragment>
      <PartsModelsTitle />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">


          {/* Sidebar*/}
          <PartsModelsSidebar />



          {/* Content  */}
          <section className="col-lg-8">


            {/* Products grid*/}
            <div className="row mx-n2">
              { filteredCharacters?.map((character) => {
                  return (
                    <SharedComponentCard character={character} page="models" key={character.keys.cid} />

                  )
                })
              }
              

              {/* Banner*/}
              {/*
            <div className="py-sm-2">
              <div className="d-sm-flex justify-content-between align-items-center bg-secondary overflow-hidden mb-4 rounded-3">
                <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">
                  <h4 className="fs-lg fw-light mb-2">Converse All Star</h4>
                  <h3 className="mb-4">Make Your Day Comfortable</h3><a className="btn btn-primary btn-shadow btn-sm" href="#">Shop Now</a>
                </div><img className="d-block ms-auto" src="img/shop/catalog/banner.jpg" alt="Shop Converse"/>
              </div>
            </div>
                */}


            </div>
            <hr className="my-3" />
            {/* Pagination*/}
            {/*
            <nav className="d-flex justify-content-between pt-2" aria-label="Page navigation">
              <ul className="pagination">
                <li className="page-item"><a className="page-link" href="#"><i className="ci-arrow-left me-2"></i>Prev</a></li>
              </ul>
              <ul className="pagination">
                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">1<span className="visually-hidden">(current)</span></span></li>
                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">2</a></li>
                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">3</a></li>
                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">4</a></li>
                <li className="page-item d-none d-sm-block"><a className="page-link" href="#">5</a></li>
              </ul>
              <ul className="pagination">
                <li className="page-item"><a className="page-link" href="#" aria-label="Next">Next<i className="ci-arrow-right ms-2"></i></a></li>
              </ul>
            </nav>
            */}
          </section>
        </div>
      </div>

    </Fragment>
  )
}

export default RouteModelsClass;