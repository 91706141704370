import {Link} from 'react-router-dom';

const SharedComponentCardCategory = (props) => {
    const { title, picUrl, linkUrl } = props;
    return (
        <div className="col-sm-4 px-2 mb-grid-gutter">
            <Link className="d-block text-center text-dark text-decoration-none me-1" to={linkUrl}>
                <img className="d-block rounded mb-3 image-fit-width" src={picUrl} alt={title} />
                <h3 className="fs-base pt-1 mb-0">{title}</h3>
            </Link>
        </div>
    )
}

export default SharedComponentCardCategory;