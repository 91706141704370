import React, { Fragment, useEffect, useState, useContext } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";

const PartsNavBarNav = () => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    const { mt } = useContext(UserContext);
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <Fragment>
            <Navbar className="navbar navbar-expand-lg navbar-light">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
                    <Nav className="justify-content-center">
                        <Nav.Item>
                            <Link className="nav-link" to="/">Home</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className="nav-link" to="/models/all">Characters</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className="nav-link" to="/search/ideas">Story Ideas</Link>
                        </Nav.Item>
                        
                        {mt === 'a' || mt === "e" ?
                            <Nav.Item>
                                <Link className="nav-link" to="/admin">Admin</Link>
                            </Nav.Item>
                            : ""}
                    </Nav>
                </Container>
            </Navbar>
        </Fragment>
    )
}

export default PartsNavBarNav;