import { createContext, useState, useEffect,useContext } from "react";
import { onAuthStateChangedListener, createUserDocumentFromAuth,  } from "../util/firebase/util.firebase";
import { UserContext } from "./user.context";

//as the actual value you want to access
export const CharactersContext = createContext({
    characters: null,
    setCharacters: () => null,
    filteredCharacters: null,
    setFilteredCharacters: () => null,

});


export const CharactersProvider = ({children}) => {
    const [characters, setCharacters] = useState(null);
    const [filteredCharacters, setFilteredCharacters] = useState(null);
    const value = {characters, setCharacters,filteredCharacters, setFilteredCharacters
                
    };

    

    return <CharactersContext.Provider value={value}>{children}</CharactersContext.Provider>
}