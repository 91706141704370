import { Fragment, useState, useEffect, useContext } from "react";
import SharedComponentCard from "../../../shared/components/shared.components.card/shared.components.card.class";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";

const PartsHomeBirthDayCharacters = () => {
    const [topCharacters, setTopCharacters] = useState([]);
    const hasPeople = Boolean(topCharacters && topCharacters.length);
    //console.log(topCharacters);

    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        let uid = -1;
        if (currentUser!==null){
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/modelDomain/V2/getBirthModelSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users =>{
                if(users.status_code==200){
                setTopCharacters(users.body);
                //console.log(topCharacters);}
                }
            })


    }, [currentUser]);

    //console.log(topCharacters);
    //console.log(hasPeople);
    
    
    return (
        <Fragment>
            { hasPeople &&
             
            <section className="container pt-md-3 pb-5 mb-md-3">
                <h2 className="h3 text-center">Happy Birthday!</h2>
                <div className="row pt-4 mx-n2">
                    {
                    topCharacters?.map((character) => {
                        return (
                            <SharedComponentCard key={character.keys.cid} character={character} page ="home" />
                        )
                    })}

                </div>
                
            </section>
            
            }
          
        </Fragment>
    )
    
}

export default PartsHomeBirthDayCharacters;