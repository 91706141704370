
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import PartsAdminCards from '../../parts/components/minis/parts.minis.admin.cards/parts.minis.admin.cards';

const emptyCharacterCounts = {
    counts: {
        character: {
            pipeline: 0,
            published: 0,
            total: 0,
            unplanned: 0,
            archived: 0,
            next: 0,
            unplannedArc: 0,
            unplannedNext: 0,
        },
        ideas: {
            pipeline: 0,
            published: 0,
            total: 0,
            unplanned: 0,
            archived: 0,
            next: 0,
            unplannedArc: 0,
            unplannedNext: 0,
        },
    },
}
const RoutesAdminHomeClass = () => {

    const { currentUser } = useContext(UserContext);
    const [characterCounts, setCharacterCounts] = useState(emptyCharacterCounts);


    useEffect(() => {
        //console.log(currentUser);
        let uid = -1;
        let response = [];
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        setCharacterCounts(emptyCharacterCounts);
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);

        fetch("https://api.liltiffyrps.com/getCountsSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log(users)
                switch (users.status_code) {
                    case 200:
                        setCharacterCounts(users.body)
                        //console.log(users.body)
                        break;

                    default:
                        setCharacterCounts(emptyCharacterCounts)
                        //console.log(characters);
                        //console.log(filteredCharacters);


                        break;
                }

                //console.log(users);(


            })




    }, [currentUser])

    console.log(characterCounts);

    return (
        <Fragment>
            <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
                <div className="px-lg-3">
                    <h3>Characters</h3>
                    <div className="row">
                        <PartsAdminCards title="Version 8 Characters" url="/admin/models/charv8" count={characterCounts ? characterCounts.counts.character.total : 0}></PartsAdminCards>
                        <PartsAdminCards title="Published Characters" url="/admin/models/charPublished" count={characterCounts ? characterCounts.counts.character.published : 0}></PartsAdminCards>
                        <PartsAdminCards title="Next Release Characters" url="/admin/models/charNext" count={characterCounts ? characterCounts.counts.character.next : 0}></PartsAdminCards>
                        <PartsAdminCards title="Pipeline Characters" url="/admin/models/charPipeline" count={characterCounts ? characterCounts.counts.character.pipeline : 0}></PartsAdminCards>
                        <PartsAdminCards title="Unplanned Characters" url="/admin/models/charUnplanned" count={characterCounts ? characterCounts.counts.character.unplanned : 0}></PartsAdminCards>
                        <PartsAdminCards title="Next Release Unplanned Characters" url="/admin/models/charUnplannedNext" count={characterCounts ? characterCounts.counts.character.unplannedNext : 0}></PartsAdminCards>
                        <PartsAdminCards title="Unreleased Unplanned Characters" url="/admin/models/charUnplannedArc" count={characterCounts ? characterCounts.counts.character.unplannedArc : 0}></PartsAdminCards>
                        <PartsAdminCards title="Archived Characters" url="/admin/models/charArchived" count={characterCounts ? characterCounts.counts.character.archived : 0}></PartsAdminCards>
                    </div>
                </div>
            </div>
            <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
                <div className="px-lg-3">
                    <h3>Story Ideas</h3>
                    <div className="row">
                        <PartsAdminCards title="Version 8 Story Ideas" url="/admin/models/ideav8" count={characterCounts ? characterCounts.counts.ideas.total : 0}></PartsAdminCards>
                        <PartsAdminCards title="Published Story Ideas" url="/admin/models/ideaPublished" count={characterCounts ? characterCounts.counts.ideas.published : 0}></PartsAdminCards>
                        <PartsAdminCards title="Next Release Story Ideas" url="/admin/models/ideaNext" count={characterCounts ? characterCounts.counts.ideas.next : 0}></PartsAdminCards>
                        <PartsAdminCards title="Pipeline Story Ideas" url="/admin/models/ideaPipeline" count={characterCounts ? characterCounts.counts.ideas.pipeline : 0}></PartsAdminCards>
                        {/*
                        <PartsAdminCards title="Unplanned Story Ideas" url="/admin/models/ideaUnplanned" count={characterCounts ? characterCounts.counts.ideas.unplanned : 0}></PartsAdminCards>
                        <PartsAdminCards title="Next Release Unplanned Story Ideas" url="/admin/models/ideaUnplannedNext" count={characterCounts ? characterCounts.counts.ideas.unplannedNext : 0}></PartsAdminCards>
                        <PartsAdminCards title="Unreleased Unplanned Story Ideas" url="/admin/models/ideaUnplannedArc" count={characterCounts ? characterCounts.counts.ideas.unplannedArc : 0}></PartsAdminCards>*/}
                        <PartsAdminCards title="Archived Story Ideas" url="/admin/models/ideaArchived" count={characterCounts ? characterCounts.counts.ideas.archived : 0}></PartsAdminCards>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default RoutesAdminHomeClass;
