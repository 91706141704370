import { useState } from "react";
import { createAuthUserWithEmailandPassword, createUserDocumentFromAuth } from "../../../util/firebase/util.firebase";
import PartsMinisFormInput from "../minis/parts.minis.formInput/parts.minis.formInput";
import './parts.components.auth.signup.styles.scss';
import PartsMinisButton from "../minis/parts.minis.button/parts.minis.button";

const defaultSignUpFormFields = {
    displayName: "",
    email: "",
    password: "",
    confirmPassword: "",
}



function PartsAuthSignUpForm() {
    const [formfields, setFormFields] = useState(defaultSignUpFormFields);
    const { displayName, email, password, confirmPassword } = formfields;

    const resetFormFields = () => {
        setFormFields(defaultSignUpFormFields);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        try {
            const {user} = await createAuthUserWithEmailandPassword(email, password);
            //console.log (response);
            await createUserDocumentFromAuth(user, { displayName });
            resetFormFields();
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                alert('Cannot create user, email already in use.');
            }
            console.log('User creation encounted an error: ', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formfields, [name]: value });
    };

    return (
        <div className='sign-up-container'>
            <h2>Don't have an Account?</h2>
            <span>Sign up with your email and password</span>
            <form onSubmit={handleSubmit}>
                <PartsMinisFormInput label = "Display Name" type="text" required onChange={handleChange} name="displayName" value={displayName} autoComplete="name" />

                <PartsMinisFormInput label = "Email" type="email" required onChange={handleChange} name="email" value={email} autoComplete="email" />

                <PartsMinisFormInput label = "Password" type="password" required onChange={handleChange} name="password" value={password} autoComplete="new-password"/>

                <PartsMinisFormInput label = "Confirm Password" type="password" required onChange={handleChange} name="confirmPassword" value={confirmPassword} autoComplete="new-password"/>

                <PartsMinisButton type="sumbit">Sign Up </PartsMinisButton>
                
            </form>
        </div>
    );
}

export default PartsAuthSignUpForm