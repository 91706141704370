import { useState, useContext, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "./image-gallery.css";
import { UserContext } from "../../../contexts/user.context";

const PartsModelGallery = (props) => {
    const { pid } = props;
    const [images, setImages] = useState([]);
    const { currentUser } = useContext(UserContext);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        fetch("https://api.liltiffyrps.com/modelDomain/V2/getPictureGallery", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&lookupType=characterSearch&version=1&test=0&aid=${uid}&pid=${pid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log (users.status_code)
                switch (users.status_code) {
                    case 200:
                        let tempImages = [];
                        users.body.pictures.gallery.map((picture) => {
                            tempImages.push({
                                original: picture.original,
                                thumbnail: picture.thumbnail,
                                fullscreen: picture.fullscreen
                            })
                        })
                        setImages(tempImages);
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, pid]);

    
    

    
    // Product gallery
    return (
        <div className="col-lg-7 pe-lg-0 pt-lg-4">

            <ImageGallery items={images} />

        {/*
            <div className="product-gallery">
                <div className="product-gallery-preview order-sm-2">
                    {thisCharacter.pictures.gallery.map((picture) => {
                        return (
                            <div className={`product-gallery-preview-item ${picture.className}`}> id={picture.place}
                                <img className="image-zoom" src={picture.fullscreen} data-zoom={picture.fullscreen} alt="Character image" />
                                <div className="image-zoom-pane"></div>
                            </div>
                        )
                    })}

                </div>
                <div className="product-gallery-thumblist order-sm-1">

                    {thisCharacter.pictures.gallery.map((picture) => {
                        return (
                            <a className={`product-gallery-thumblist-item ${picture.className}`} href={`#${picture.place}`}>
                                <img src={picture.thumbnail} alt="Product thumb" />
                            </a>
                        )
                    })}



                </div>
                </div>*/}
        </div>
    )
            
}

export default PartsModelGallery;