import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';

const PartsAdminCards = ({title, url, count}) => {

    return (
        <div className="col-12 col-md-4">
            <Card className="h-100">
                {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                        There are currently {count} {title}.
                    </Card.Text>
                    <Link to={url}><Button variant="primary">View All {title}</Button></Link>

                </Card.Body>
            </Card>
        </div>
    )
}

export default PartsAdminCards;
