import { Fragment, useState, useEffect, useContext, } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";
import { NavLink } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Accordion from 'react-bootstrap/Accordion';
import { CharactersContext } from "../../../contexts/characters.context";

function PartsModelsSidebar() {

  const [genres, setGenres] = useState([]);
  const [pickedGenre, setPickedGenre] = useState([]);
  const [pickedState, setPickedState] = useState([]);
  const [pickedBd, setPickedBd] = useState([]);
  
  const { characters, setCharacters } = useContext(CharactersContext);
  const { filteredCharacters, setFilteredCharacters } = useContext(CharactersContext);

  //console.log(topCharacters);

  const { currentUser } = useContext(UserContext);
  const { mt } = useContext(UserContext);

  useEffect(() => {
    let uid = -1;
    if (currentUser !== null) {
      uid = currentUser.uid;
    }
    //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
    fetch("https://api.liltiffyrps.com/modelDomain/V2/getGenreInfo", {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: `title=meow&release=1&version=1&test=0&aid=${uid}`
    }
    )
      .then(response => response.json())
      .then(users => {
        setGenres(users.body);
        //console.log(users);
        setPickedGenre("modern");
        setPickedBd("");
        setPickedState("publish");
      })


  }, [currentUser]);

  const onChangeGenreHandler = (thisGenre) => {
    setPickedGenre(thisGenre);
    //console.log(pickedGenre);
    //bigFilter();

  }


  useEffect(() => {
    const filteredOne = characters?.filter((character) => {
      return character.genre.search.includes(pickedGenre);
    });
    const filteredTwo = filteredOne?.filter((character) => {
      return character.status.birthday.includes(pickedBd);
    });
    const filteredThree = filteredTwo?.filter((character) => {
      return character.status.state.includes(pickedState);
    });
    setFilteredCharacters(filteredThree);

  }, [characters, pickedGenre, pickedBd, pickedState]);


  const handleGenreChange = async (e) => {
    setPickedGenre(e.target.value);
    //console.log(characters);
    //console.log(filteredCharacters);
  }

  const handleBdChange = async (e) => {
    setPickedBd(e.target.value);
    //console.log(characters);
    //console.log(filteredCharacters);
  }

  const handleStateChange = async (e) => {
    setPickedState(e.target.value);
    //console.log(characters);
    //console.log(filteredCharacters);
  }

  {/*
  const filteredCharacters = this.state.characters.filter((character)=>{
                  return character.name.longname.toLowerCase.includes(searchString);
                })*/}
  return (
    <Fragment>
      {/* Sidebar*/}
      <aside className="col-lg-4">
        {/* Sidebar*/}
        <div className="offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg py-1" id="shop-sidebar" style={{ maxWidth: 22 + 'rem' }}>
          <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
              <Accordion.Header>Birthday</Accordion.Header>
              <Accordion.Body>
                <div>
                  <input type="radio" value="" name="bd" defaultChecked={true} onChange={handleBdChange} />
                  <label className="m-1 form-option-label rounded-circle"><i className="fad fa-asterisk"></i></label> All<br />
                  <input type="radio" value="today" name="bd" defaultChecked={false} onChange={handleBdChange} />
                  <label className="m-1 form-option-label rounded-circle"><i className="fad fa-calendar-day"></i></label> Today<br />
                  
                </div>

              </Accordion.Body>
              </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Genre</Accordion.Header>
              <Accordion.Body>
                <div>
                  <input type="radio" value="" name="genre" defaultChecked={false} onChange={handleGenreChange} />
                  <label className="m-1 form-option-label rounded-circle"><i className="fad fa-asterisk"></i></label> All<br />
                  {genres?.map((genre) => {
                    return (
                      <Fragment key={genre.name}>
                        <input type="radio" value={genre.search} name="genre" defaultChecked={genre.classText} onChange={handleGenreChange} />
                        <label className="m-1 form-option-label rounded-circle"><i className={`${genre.icon} ${genre.classText}`}></i></label> {genre.name} <br />
                      </Fragment>
                    )
                  })}
                </div>

              </Accordion.Body>
            </Accordion.Item>
            {mt === 'a' || mt === 'e' ?
              <Accordion.Item eventKey="2">
                <Accordion.Header>Process State</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <input type="radio" value="" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-asterisk"></i></label> All<br />
                    <input type="radio" value="archive" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-box"></i></label> Archived<br />
                    <input type="radio" value="unreleased" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-empty-set"></i></label> Unreleased Unplanned<br />
                    <input type="radio" value="nexunp" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-empty-set"></i></label> Next Release Unplanned<br />
                    <input type="radio" value="unplanned" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-empty-set"></i></label> Unplanned<br />
                    <input type="radio" value="pipeline" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-keyboard"></i></label> Pipeline<br />
                    <input type="radio" value="next" name="state" defaultChecked={false} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-arrow-right"></i></label> Next<br />
                    <input type="radio" value="publish" name="state" defaultChecked={true} onChange={handleStateChange} />
                    <label className="m-1 form-option-label rounded-circle"><i className="fad fa-newspaper"></i></label> Published<br />
                  </div>

                </Accordion.Body>
              </Accordion.Item>
           
            :
            ""
          }
          </Accordion>
          
          {/*
              <input className="search-box m-3" type="search" placeholder="search character names" onChange={(event) => {
                const searchString = event.target.value.toLowerCase;
                
                this.setState(()-> {return {searchString}})
              }}/>
            */}

        </div>
      </aside>
    </Fragment>
  )
}

export default PartsModelsSidebar;