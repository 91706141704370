import { useEffect, useState, useContext } from "react";
import { Fragment } from "react";
import { UserContext } from "../../contexts/user.context";

const RoutesEditRpLogList = ({ timelineKey }) => {
    const [logs, setLogs] = useState();
    const { currentUser } = useContext(UserContext);
    let cleanCardText = "";
    let cleanCardTextCount = 0;

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/roleplayDomain/V2/getLogForTimeline", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&timelineKey=${timelineKey}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log(users);
                setLogs(users.body);
            })


    }, [currentUser, timelineKey]);

    //console.log(logs);

    return (
        <Fragment>
            {logs?.map((log) => {
                var orgCardText = log.cardText;
                var index = orgCardText.indexOf(":");  // Gets the first index where a space occours
                var title = orgCardText.slice(0, index); // Gets the first part
                var logText = orgCardText.slice(index + 1);
                cleanCardText = logText.split('<br>');
                cleanCardTextCount = 0;
                return (
                    <div key={log.id}>
                        <h4 className="text-center">{title}</h4>
                        {cleanCardText?.map((cardTextSegment) => {
                            cleanCardTextCount++;
                            return (<p key={cleanCardTextCount} className="text-muted" id="colorOption">{cardTextSegment}</p>);

                        })
                        }

                        <br /><hr />
                    </div>
                )
            })

            }
        </Fragment>
    )
}

export default RoutesEditRpLogList;
