export const SchemaPlaceLight = {
    loc22Name: "",
    loc22Subtitle: "",
    loc22CardText: "",
    loc22Icon: "",
    
}

export const SchemaPlaceFull = {

    loc22Id: 0,
    loc22Name: "",
    loc22Subtitle: "",
    loc22CardText: "",
    loc22Pipeline: "s",
    loc22DateAdded: "1970-12-31T12:00",
    loc22Version: 8,
    loc22Rating: 0,
    loc22Cover: "",
    loc22Color: "",
    loc22Icon: "",
    loc22Avatar: "",
    loc22PlanetKey: 0,
        
}