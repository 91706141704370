import { useParams } from "react-router";
import Table from 'react-bootstrap/Table';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { Link } from "react-router-dom";

const RoutesAdminCharactersClass = () => {
    let { stateText } = useParams();
    let pageTitle = "";
    let lookupType = "";
    let modelSource = "";
    switch (stateText) {
        case "charPublished":
            pageTitle = "Published Characters";
            lookupType = "allPublishedModels";
            modelSource = "Model";
            break;
        case "charPipeline":
            pageTitle = "Pipeline Characters";
            lookupType = "allPipelineModels";
            modelSource = "Model";
            break;
        case "charUnplanned":
            pageTitle = "Unplanned Characters";
            lookupType = "allUnplannedModels";
            modelSource = "Model";
            break;
        case "charUnplannedArc":
            pageTitle = "Unreleased Unplanned Characters";
            lookupType = "allUnplannedArcModels";
            modelSource = "Model";
            break;
        case "charUnplannedNext":
            pageTitle = "Next Unplanned Characters";
            lookupType = "allUnplannedNextModels";
            modelSource = "Model";
            break;
        case "charNext":
            pageTitle = "Next Release Characters";
            lookupType = "allNextModels";
            modelSource = "Model";
            break;
        case "charArchived":
            pageTitle = "Archived Characters";
            lookupType = "allArchiveModels";
            modelSource = "Model";
            break;
        case "charv8":
            pageTitle = "All v8 Characters";
            lookupType = "allModels";
            modelSource = "Model";
            break;
        case "ideaPublished":
            pageTitle = "Published Story Ideas";
            lookupType = "allPublishedIdeas";
            modelSource = "Source";
            break;
        case "ideaPipeline":
            pageTitle = "Pipeline Story Ideas";
            lookupType = "allPipelineIdeas";
            modelSource = "Source";
            break;
        case "ideaUnplanned":
            pageTitle = "Unplanned Story Ideas";
            lookupType = "allUnplannedIdeas";
            modelSource = "Source";
            break;
        case "ideaUnplannedArc":
            pageTitle = "Unreleased Unplanned Story Ideas";
            lookupType = "allUnplannedArcIdeas";
            modelSource = "Source";
            break;
        case "ideaUnplannedNext":
            pageTitle = "Next Unplanned Story Ideas";
            lookupType = "allUnplannedNextIdeas";
            modelSource = "Source";
            break;
        case "ideaNext":
            pageTitle = "Next Release Story Ideas";
            lookupType = "allNextIdeas";
            modelSource = "Source";
            break;
        case "ideaArchived":
            pageTitle = "Archived Story Ideas";
            lookupType = "allArchiveIdeas";
            modelSource = "Source";
            break;
        case "ideav8":
            pageTitle = "All v8 Story Ideas";
            lookupType = "allIdeas";
            modelSource = "Source";
            break;
        case "rpv8":
            pageTitle = "All Roleplays";
            lookupType = "allRps";
            modelSource = "Source";
            break;
            

    }

    const { currentUser } = useContext(UserContext);
    const [characterTable, setCharacterTable] = useState([]);


    useEffect(() => {
        //console.log(currentUser);
        let uid = -1;
        let response = [];
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        setCharacterTable([]);
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);

        fetch("https://api.liltiffyrps.com/getThisSearch", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&lookupType=${lookupType}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log(users)
                switch (users.status_code) {
                    case 200:
                        setCharacterTable(users.body)
                        //console.log(users.body)
                        break;

                    default:
                        setCharacterTable([])
                        //console.log(characters);
                        //console.log(filteredCharacters);


                        break;
                }

                //console.log(users);(


            })




    }, [currentUser, stateText])

    return (
        <Fragment>
            <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
                <div className="px-lg-3">
                    <div className="row">
                        <h3>{pageTitle}</h3>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Genre</th>
                                    <th>Name</th>
                                    <th>{modelSource}</th>
                                    <th>Thumbnail</th>

                                </tr>
                            </thead>
                            <tbody>
                                {characterTable?.map((tableRow) => {
                                    return (
                                        <tr key={tableRow.keys.cid}>
                                            <td><Link to={tableRow.editUrl}>Edit</Link></td>{/*Link*/}
                                            <td><Link to={tableRow.viewUrl}>View</Link></td>{/*Link*/}
                                            <td><i className={tableRow.genre.icon}></i> {tableRow.genre.name}</td>
                                            <td>{tableRow.name.longName}</td>{/*CharacterName*/}
                                            <td>{tableRow.modelName}</td>{/*ModelName*/}
                                            <td><img className="td-img" src={tableRow.pictures.thumbnail}></img></td>{/*Thumb*/}

                                        </tr>
                                    )
                                })}
                                <tr></tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>



        </Fragment>
    )
}

export default RoutesAdminCharactersClass;
