//import './parts.minis.formInput.styles.scss';
import { Link } from "react-router-dom";

import { Fragment } from "react";

const PartsMinisTitle = ({ title, midText, midLink, hasMid }) => {
  
    return (
        <div className="page-title-overlap bg-dark pt-4">
            <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                    <Link className="text-light" to="/"><i className="fad fa-house"></i> Home</Link>
                    {hasMid && (
                        <Fragment>
                            <span className="text-light"> / </span>        
                            <Link className="text-light" to={midLink}>{midText}</Link>
                        </Fragment>
                    )}
                    <span className="text-light"> / </span>
                    <span className="text-secondary">{title}</span>
                </div>
                <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                    <h1 className="h3 text-light mb-0">{title}</h1>
                </div>
            </div>
        </div>
    )
}

export default PartsMinisTitle;