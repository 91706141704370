import { createContext, useState, useEffect, } from "react";
import { onAuthStateChangedListener, createUserDocumentFromAuth,  } from "../util/firebase/util.firebase";

//as the actual value you want to access
export const UserContext = createContext({
    currentUser: null,
    setCurrentUser: () => null,
    displayName: null,
    setDisplayName: () => null,
    aN: null,
    setAN: () => null,
    aP: null,
    setAP: () => null,
    aU: null,
    setAU: () => null,
    em: null,
    setEm: () => null,
    mt: null,
    setMt: () => null,
    rp: null,
    setRp: () => null,


});


export const UserProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [aN, setAN] = useState(null);
    const [aP, setAP] = useState(null);
    const [aU, setAU] = useState(null);
    const [em, setEm] = useState(null);
    const [mt, setMt] = useState(null);
    const [rp, setRp] = useState(null);
    //const [displayName, setDisplayName] = useState(null);
    //const [displayName, setDisplayName] = useState(null);
    const value = {currentUser, setCurrentUser,
    displayName, setDisplayName, aN, setAN, aP,setAP,
    aU, setAU, em, setEm, mt, setMt, rp, setRp
    
    };

    useEffect(()=>{
        const unsubscribe = onAuthStateChangedListener((user)=>{
            if(user) {
                createUserDocumentFromAuth(user);
            }
           
            //console.log(user);
            setCurrentUser(user);
            //UpdateUserInfoFromAPI(currentUser, setCurrentUser);
        })
        return unsubscribe
    },[])

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}