import { Fragment, useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts/user.context";
import RoutesEditRpLogList from "../../../routes/routes.editrp.class/routes.editrp.loglist";
import Accordion from 'react-bootstrap/Accordion';

const PartsRoleplayTimeLine = ({ rpId }) => {
    const [timeline, setTimeline] = useState([]);
    const { currentUser } = useContext(UserContext);
    const { mt } = useContext(UserContext);

    useEffect(() => {
        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }
        //console.log(`title=meow&release=1&version=1&test=0&aid=${uid}`);
        fetch("https://api.liltiffyrps.com/roleplayDomain/V2/getTimelineForRP", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&version=1&test=0&aid=${uid}&rid=${rpId}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log(users);
                setTimeline(users.body);
            })


    }, [currentUser, rpId]);
    let timeCount = 0;
    return (
        <div className="pt-3">
            <hr /><br />
            <Accordion>
                {timeline?.map((scene) => {
                timeCount++
                return (
                    <Accordion.Item key={scene.id} eventKey={(timeCount - 1)}>
                        <Accordion.Header>Scene: {scene.cardText}</Accordion.Header>
                        <Accordion.Body>
                            <h4>Scene Start: {scene.dateTimeFormatted}</h4>
                            <h4>Scene Location: {scene.place}</h4>
                            <br /><hr /><br />
                            <RoutesEditRpLogList timelineKey={scene.id}></RoutesEditRpLogList>
                        </Accordion.Body>
                    </Accordion.Item>

                )
            })}
            </Accordion>
            
        </div>
    )


}

export default PartsRoleplayTimeLine;