import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithRedirect,
    signInWithPopup,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from 'firebase/auth';
import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
} from 'firebase/firestore'

import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { CharactersContext } from '../../contexts/characters.context';

const firebaseConfig = {
    apiKey: "AIzaSyAdAr1Z6d_UfNJOMVBaMdyCGortccz57II",
    authDomain: "liltiffyroleplays.firebaseapp.com",
    projectId: "liltiffyroleplays",
    storageBucket: "liltiffyroleplays.appspot.com",
    messagingSenderId: "648919379893",
    appId: "1:648919379893:web:fafed4c4485f6f9d00b3fc"
};



// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const providerGoogle = new GoogleAuthProvider();
providerGoogle.setCustomParameters({
    prompt: "select_account",
})

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, providerGoogle);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, providerGoogle);

export const firestoreDB = getFirestore();

export const createUserDocumentFromAuth = async (userAuth, additionInformation = {}) => {
    if (!userAuth) return;

    const userDocRef = doc(firestoreDB, 'users', userAuth.uid);

    //console.log(userDocRef);

    const userSnapshot = await getDoc(userDocRef);


    //console.log(userSnapshot);

    if (!userSnapshot.exists()) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();

        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                aN: false,
                aP: false,
                aU: false,
                ...additionInformation,
            })
            
        } catch (error) {
            console.log('Error creating user: ', error.message);
        }
    }
    //console.log(userDocRef);
    return userDocRef;

};

export const returnUserDocForRef = async(userAuth) => {
    if (!userAuth) return;

    const userDocRef = doc(firestoreDB, 'users', userAuth.uid);
    return await getDoc(userDocRef);
}

export const createAuthUserWithEmailandPassword = async (email, password) => {
    if (!email || !password) return;
    
    return await createUserWithEmailAndPassword(auth, email, password);
}

export const signInAuthUserWithEmailandPassword = async (email, password) => {
    if (!email || !password) return;
    
    return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => {
    await signOut(auth);

}

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);
