import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const PartsModelsTitle = () => {
    return (
        <Fragment>
            <div className="page-title-overlap bg-dark pt-4">
                <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                    <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                        <Link className="text-light" to="/"><i className="fad fa-house"></i> Home</Link>
                        <span className="text-light"> / </span>
                        <span className="text-secondary">Characters</span>
                    </div>
                    <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                        <h1 className="h3 text-light mb-0">Characters</h1>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PartsModelsTitle;