import { Fragment } from "react";
import { Link } from "react-router-dom";

const PartsModelTitle = (props) => {
    const {thisCharacter}=props;
    return (
        <Fragment>
            <div key="thisCharacter.id" className="container d-lg-flex justify-content-between py-2 py-lg-3">
                <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                    <Link className="text-light" to="/">
                        <i className="fad fa-house"></i> Home
                    </Link>
                    <span className="text-light"> / </span>
                    <Link className="text-light" to="/models/all">Characters
                    </Link>
                    <span className="text-light"> / </span>
                    <span className="text-secondary">{thisCharacter.name.longName}</span>
                </div>
                <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                    <h1 className="h3 text-light mb-0">{thisCharacter.name.longName}</h1>
                </div>
            </div>
        </Fragment>
    )
}

export default PartsModelTitle;