import { useState, useContext, useEffect, Fragment } from "react";
import { UserContext } from "../../../contexts/user.context";
import { NavLink } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const PartsModelGenreSelect = (props) => {
    const { cid, mid } = props;
    const [genres, setGenres] = useState([]);
    const { currentUser } = useContext(UserContext);

    useEffect(() => {

        let uid = -1;
        if (currentUser !== null) {
            uid = currentUser.uid;
        }

        //console.log(`title=meow&release=1&lookupType=characterSearch&version=1&test=0&aid=${uid}&mid=${MiD}&cid=${CiD}`);

        fetch("https://api.liltiffyrps.com/modelDomain/V2/getGenreForCharacter", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: `title=meow&release=1&lookupType=characterSearch&version=1&test=0&aid=${uid}&mid=${mid}&cid=${cid}`
        }
        )
            .then(response => response.json())
            .then(users => {
                //console.log(users.status_code)
                switch (users.status_code) {
                    case 200:
                        //console.log(users);
                        setGenres(users.body);
                        break;
                    case 300:
                        //not found

                        break;

                    case 400:
                    //unauthorized

                }

            })



    }, [currentUser, mid, cid]);





    // Product gallery
    return (
        <div className="">
            {genres.map((genre) => {
                return (

                    <Fragment key={genre.name}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip" {...props}>
                            {genre.name}
                        </Tooltip>}
                    >
                        <NavLink to={genre.url}><label className="m-1 form-option-label rounded-circle"><i className={`${genre.icon} ${genre.classText}`}></i></label></NavLink>
                    </OverlayTrigger>
                    </Fragment>

                )
            })}

        </div>
    )

}

export default PartsModelGenreSelect;