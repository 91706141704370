import React, { Fragment, useContext, useEffect, useState } from "react";
import PartsModelsTitle from "../../parts/components/parts.components.models.title/parts.components.models.title";
import PartsModelsSidebar from "../../parts/components/parts.components.models.sidebar/parts.components.models.sidebar";
import SharedComponentCard from "../../shared/components/shared.components.card/shared.components.card.class";
import { UserContext } from "../../contexts/user.context";
import { CharactersContext } from "../../contexts/characters.context";
import { useParams } from "react-router-dom";
import { IdeasContext } from "../../contexts/ideas.context";
import SharedComponentIdeaCard from "../../shared/components/shared.components.card/shared.components.ideaCard.class";
import PartsIdeasSidebar from "../../parts/components/parts.components.ideas.sidebar/parts.components.ideas.sidebar";
import PartsMinisTitle from "../../parts/components/minis/parts.minis.title/parts.minis.title";

const DisplayIdeasPage = ({ searchType, theseIdeas }) => {
  //console.log(theseIdeas);
  //console.log(searchType);

  return (
    <Fragment>
      <PartsMinisTitle title="Story Ideas" midText="" midLink="" hasMid={false}></PartsMinisTitle>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          {/* Sidebar*/}
          <PartsIdeasSidebar />
          {/* Content  */}
          <section className="col-lg-8">
            {/* Products grid*/}
            <div className="row mx-n2">
              {theseIdeas?.map((idea) => {
                return (
                  <Fragment key={idea.id}>
                    <SharedComponentIdeaCard idea={idea} page="ideas" key={idea.id} />
                  </Fragment>
                )
              })
              }
            </div>
            <hr className="my-3" />
          </section>
        </div>
      </div>

    </Fragment>
  )
}

const DisplayCharacterPage = ({ searchType, theseCharacters }) => {
  //console.log(theseIdeas);
  //console.log(searchType);

  return (
    <Fragment>
      <PartsMinisTitle title="Story Ideas" midText="" midLink="" hasMid={false}></PartsMinisTitle>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          {/* Sidebar*/}
          <PartsModelsSidebar />
          {/* Content  */}
          <section className="col-lg-8">
            {/* Products grid*/}
            <div className="row mx-n2">
              {theseCharacters?.map((character) => {
                return (
                  <Fragment key={character.keys.cid}>
                    <SharedComponentCard character={character} page="models" key={character.keys.cid} />
                  </Fragment>
                )
              })
              }
            </div>
            <hr className="my-3" />
          </section>
        </div>
      </div>

    </Fragment>
  )
}

const SearchTypeSwitch = ({ searchType, ideasFound, charactersFound }) => {
  //console.log(searchType);
  //console.log(ideasFound);
  switch (searchType) {
    case "ideas":
      return (<DisplayIdeasPage searchType={searchType} theseIdeas={ideasFound}></DisplayIdeasPage>)
      break;
    case "models":
      return (<DisplayCharacterPage searchType={searchType} theseCharacters={charactersFound}></DisplayCharacterPage>)
      break;


  }
}

const RouteSearchClass = () => {
  let { searchType } = useParams();
  const { currentUser } = useContext(UserContext);
  const { characters, setCharacters } = useContext(CharactersContext);
  const { ideas, setIdeas } = useContext(IdeasContext);
  const { filteredCharacters, setFilteredCharacters } = useContext(CharactersContext);
  const { filteredIdeas, setFilteredIdeas } = useContext(IdeasContext);
  useEffect(() => {

    //setCharacters([]);
    //setIdeas([]);


  }, [])


  useEffect(() => {
    let uid = -1;
    let response = [];
    if (currentUser !== null) {
      uid = currentUser.uid;
    }

    switch (searchType) {
      case "ideas":
        setIdeas([]);
        setFilteredIdeas([]);
        fetch("https://api.liltiffyrps.com/getThisSearch", {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: `title=meow&release=1&version=1&test=0&lookupType=getAllIdeasSearch&aid=${uid}`
        }
        )
          .then(response => response.json())
          .then(users => {
            switch (users.status_code) {
              case 200:
                setIdeas(users.body);
                setFilteredIdeas(users.body);
                break;

              default:
                setIdeas([])
                setFilteredIdeas([])
                break;
            }
          })
        break;
      case "models":
        setIdeas([]);
        setFilteredIdeas([]);
        fetch("https://api.liltiffyrps.com/getThisSearch", {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: `title=meow&release=1&version=1&test=0&lookupType=getAllModelsSearch&aid=${uid}`
        }
        )
          .then(response => response.json())
          .then(users => {
            switch (users.status_code) {
              case 200:
                setCharacters(users.body);
                setFilteredCharacters(users.body);
                break;

              default:
                setCharacters([])
                setFilteredCharacters([])
                break;
            }
          })
        break;
    }
  }, [currentUser, searchType])


  return (
    <SearchTypeSwitch searchType={searchType} ideasFound={filteredIdeas} charactersFound={filteredCharacters}></SearchTypeSwitch>

  )

  //console.log(ideasFound);
  //console.log(filteredIdeas);


}

export default RouteSearchClass;