import React, { Fragment } from "react";
import { NavbarBrand } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap'
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";
import { signOutUser } from "../../../util/firebase/util.firebase";

const PartsNavBarSearch = () => {
    const { currentUser, displayName } = useContext(UserContext);
    let userName = "";
    //console.log(currentUser);
    //console.log(currentUser.email);
    if (currentUser !== null) {
        if (displayName !== null){
            userName = displayName;
        } else if (currentUser.displayName !== null) {
            userName = currentUser.displayName;
        } else {
            userName = currentUser.email;
        }
    }

    return (
        <Fragment>
            <Navbar className="navbar navbar-expand-lg navbar-light">
                <Container>
                    <LinkContainer to="/">
                        <NavbarBrand ><img src="https://images.liltiffyrps.com/logo-Dark.png" width="142" alt="Lil Tiffy's Rps" /></NavbarBrand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
                    <Nav className="me-auto">
                        <div className="input-group d-none d-lg-flex mx-4">
                            {/*<input className="form-control rounded-end pe-5" type="text" placeholder="Search for products" /><i className="ci-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3"></i>*/}
                        </div>
                        <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                            {/*
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <a className="navbar-tool navbar-stuck-toggler" href="#">
                                <span className="navbar-tool-tooltip">Expand menu</span>
                                <div className="navbar-tool-icon-box">
                                    <i className="navbar-tool-icon ci-menu"></i>
                                </div>
                            </a>
                            <a className="navbar-tool d-none d-lg-flex" href="account-wishlist.html">
                                <span className="navbar-tool-tooltip">Wishlist</span>
                                <div className="navbar-tool-icon-box">
                                    <i className="navbar-tool-icon ci-heart"></i>
                                </div>
                            </a>
                            */}
                            {currentUser ? (
                                
                                    <div className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" to="auth" data-bs-toggle="modal">
                                        <div className="navbar-tool-icon-box">
                                        <i className="fad fa-user"></i>
                                        </div>


                                        <div className="navbar-tool-text ms-n3">
                                            <small>Hello, {userName}</small>My Account |
                                            <span onClick={signOutUser}> Sign Out</span>
                                        </div> 
                                        
                                    
                                   
                                </div>
                            ) : (
                                <Link className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" to="auth" data-bs-toggle="modal">
                                    <div className="navbar-tool-icon-box">
                                    <i className="fad fa-user"></i>
                                    </div>


                                    <div className="navbar-tool-text ms-n3">
                                        <small>Hello, Sign in</small>My Account
                                    </div>
                                </Link>
                            )}

                        </div>
                    </Nav>

                </Container>
            </Navbar>

        </Fragment>
    )
}

export default PartsNavBarSearch;